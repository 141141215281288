import React, { useEffect, useRef, useState, Component }  from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {getPages} from '../../../../actions';
import { getBaseUrl } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

import cx from 'classnames';
import './filterable-gallery.less';

class FilterableGallery extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  //  static propTypes = {
  //   getGalleryImages: PropTypes.func.isRequired,
  //   galleryImages: PropTypes.arrayOf(
  //     PropTypes.shape({
  //       id: PropTypes.string,
  //       type: PropTypes.string,
  //       description: PropTypes.string,
  //       review_state: PropTypes.string,
  //       title: PropTypes.string,
  //     }),
  //   ).isRequired,
  // };
  static propTypes = {
    getPages: PropTypes.func.isRequired,
    error: PropTypes.string,
    // gallery: PropTypes.arrayOf(
    //   PropTypes.shape({
    //     '@id': PropTypes.string,
    //     '@type': PropTypes.string,
    //     description: PropTypes.string,
    //     review_state: PropTypes.string,
    //     title: PropTypes.string,
    //   })
    // ),
    lodaded: PropTypes.bool,
    loading: PropTypes.bool,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Navigation
   */
   constructor(props) {
    super(props);

    this.blockClass = "filterableGalleryBlock";

    const selector = this.props.selector != null && this.props.selector.trim().length > 0 ? this.props.selector : "gallery";
    this.props.getPagesFromPlone(selector);
    this.state = {
      filter: "show-all"
    };
  }

  labels = () => this.props.filters ? this.props.filters.split(',').map(item => item.split(" ").map(word => this.capitalizeFirstLetter(word.trim())).join(" ")) : [];

  listItems = () => {

    return ["Show All"].concat(this.labels()).map( (elem) =>
                          <li key={this.filterToTag(elem)}>
                            <a onClick={() => this.setState({ filter: this.filterToTag(elem) })}
                               className={this.navItemStyle(elem, this.cleanFilter(this.state.filter))}>
                                   {elem}
                            </a>
                          </li>
                        );
  }

  cleanFilter = (filter) => (filter!="" && filter!=null) ? filter : "show-all";

  capitalizeFirstLetter = (aString) => aString.toString().charAt(0).toUpperCase() + aString.slice(1);

  filterToTag = (aString) =>  aString.toString().toLowerCase().trim().replace(" ", "-");

  pageHasTag = (pageData, tagToSeach) => pageData["tags"].includes(tagToSeach);


  navItemStyle = (elem, currentFilter) =>  (currentFilter == this.filterToTag(elem)) ? "active" : "inactive";

  pageCount = () => Math.ceil(Math.max(1, this.visibleItems().length / (this.props.elementsPerRow * this.props.rows)));

  visibleItems = () => this.props.pageData.filter((item) => (this.cleanFilter(this.state.filter)=="show-all" || this.pageHasTag(item, this.cleanFilter(this.state.filter))) );

  gallery = () => this.visibleItems().map((item) =>
    <div className="filterable-gallery-item">
      <a href={item.link}>
        <img src={item.img}/>
        <div className="gallery-item-title">{item.title}</div>
      </a>
    </div>
  );

  pagination = () => Array(this.pageCount()).fill(0).map( (item) =>
    <li className="filterable-gallery-page">*</li>
  );

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
   render() {
    const { settings } = config;
    // const items = this.props.get

    return (
      <div className={this.blockClass} >
        {
           this.labels().length > 1 ?
             <ul className="filterable-gallery-nav">
               {this.listItems() }
             </ul>
           : <div>no labels</div>
         }


         {
           this.visibleItems().length>1 ?
           <div className="filterable-gallery-items">
             {this.gallery()}
           </div>
           : <div className="filterable-gallery-items"></div>
         }



       <div className="filterable-gallery-pagination">
         <ul className="filterable-gallery-pages">
           {this.pagination()}
         </ul>
       </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  
  const { error, loaded, loading, pages } = state.pages || {error: "", loaded: false, loading: false, pages: []};
  const pageData = pages.map(page => {
      let rObj = {}
      rObj["title"] = page.title;
      rObj["description"] = page.description;
      rObj["meta"] = page.subjects.join(" ") + " | " + page.modified.substring(0,10);
      rObj["img"] = page.preview_image.download;
      rObj["link"] = page.id;
      rObj['tags'] = page['subjects'].join(" ");
      return rObj;
  });

  const propsStruct = {
    ...ownProps.data,
    pages: pages,
    loaded: loaded,
    loading: loading,
    error: error,
    activeFilter: state.activeFilter == null || state.activeFilter == "" ? 'show-all' : state.activeFilter,
    elementsPerRow: 3,
    pageData: pageData,
  }

  return propsStruct;
}

const mapDispatchToProps = {
  // ... normally is an object full of action creators
  getPagesFromPlone: getPages
}

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FilterableGallery);

// 3. Use setState to store activeFilter (props are RO)
