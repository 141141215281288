import React, { useEffect, useRef, useState }  from 'react';
import './external-link-card.less';



const ExternalLinkCardView = (props) => {
  let ref = useRef();
  const [config, setConfig] = useState();

  // const stitch = props.data.stitch;
  const placeTextLeft = props.data.placeTextLeft ? "placeTextLeft" : "";
  const isEdit = props.data.edit ? true : false;

  const blockClass = "externalLinkCard section " + props.data.theme + " " + placeTextLeft;
  const link = (props.data.link != undefined) ? props.data.link[0].getURL : "";

  const imgUrl = () => {
    // console.log(JSON.stringify(props.data.image));

    let imagePath = "";
    try {
      // console.table(props.data);
      imagePath = props.data.image[0]["getURL"].replace(process.env.RAZZLE_API_PATH, "") + '/@@images/image.png';
    } catch (e) {
      console.log("Error in IMG URL in ExternalLinkCardView: " + e.toString());
    }

    return imagePath;
  }

  const getHeading = () => {
    // console.log(JSON.stringify(props.data.image));

    let heading = "";

    try {
      heading = props.data.link[0].Title;
    } catch (e) {
      console.log("Error in Heading in ExternalLinkCardView: " + e.toString());
    }

    if (props.data.heading != null) {
      heading = props.data.heading;
    }

    return heading;
  }

  const getDescription = () => {
    // console.log(JSON.stringify(props.data.image));

    let description = "";
    try {
      description = props.data.link[0].Description;
    } catch (e) {
      console.log("Error in Description in ExternalLinkCardView: " + e.toString());
    }

    if (props.data.description != null) {
      description = props.data.description;
    }

    return description;
  }

  // const getLink = () => {
  //   // console.log(JSON.stringify(props.data));
  //   let link = "";
  //   try {
  //     link = props.data.link[0].getURL;
  //   } catch (e) {
  //     console.log("Error in props.data.link[0].getURL in ExternalLinkCardView: " + e.toString());
  //     console.log(props.data);
  //   }

  //   return link;
  // }

  const getColorSetting = () => {
    try {
      return this.props.data.blackAndWhite ? "blackAndWhite external-link-card-image" : "color external-link-card-image";
    } catch(e) {
      return "color";
    }
  }


  return <div className={blockClass} key="item-{Math.floor(Math.random() * 10000)}">
          <a {...(!isEdit ? {
            href: link
            } : {
              className:"disabled" 
            })} >
            <div className="external-link-card-image">
              <div className="height-controller"></div>
              <img src={imgUrl()} alt="img"></img>
            </div>

            <div className="external-link-pointer">
            </div>

            <div className="external-link-card-text">
              <div className="external-link-card-title">
                {getHeading()}
              </div>

              <div className="external-link-card-description">
                {getDescription()}
              </div>
            </div>
          </a>

        </div>;
};

export default ExternalLinkCardView;
