/**
 * Add your action types here.
 * @module constants/ActionTypes
 * @example
 * export const UPDATE_CONTENT = 'UPDATE_CONTENT';
 */

 export const GET_GALLERY_IMAGES = 'GET_GALLERY_IMAGES';
 export const GET_GALLERY_IMAGES_SUCCESS = 'GET_GALLERY_IMAGES_SUCCESS';

 export const GET_PAGES = 'GET_PAGES';
 export const GET_PAGES_SUCCESS = 'GET_PAGES_SUCCESS';

 export const GET_RELATED_PAGES = 'GET_RELATED_PAGES';
 export const GET_RELATED_PAGES_SUCCESS = 'GET_RELATED_PAGES_SUCCESS';

 export const GET_FOOTER_LINKS = 'GET_FOOTER_LINKS';
 export const GET_FOOTER_LINKS_SUCCESS = 'GET_FOOTER_LINKS_SUCCESS';

 export const CONTACT_FORM_SUBMIT = 'CONTACT_FORM_SUBMIT';
 export const CONTACT_FORM_SUBMIT_SUCCESS = 'CONTACT_FORM_SUBMIT_SUCCESS';
