import React, { useEffect, useRef }  from 'react';
import './metrics.less';

let toThousands = (numberAsText) => {
  let suffix = '';
  let number = '';

  try {
    if ((numberAsText.length > 3) && (numberAsText.length < 7)) {
      suffix = 'K';
      number = parseInt(numberAsText) / 1000;
    } else if ((numberAsText.length > 6) && (numberAsText.length < 10)) {
      suffix = 'M';
      number = parseInt(numberAsText) / 1000000;
    } else if ((numberAsText.length > 9) && (numberAsText.length < 13)) {
      suffix = 'B';
      number = parseInt(numberAsText) / 1000000000;
    }
    let finalText = number + suffix;
    return finalText;
  } catch (ex) {
    return numberAsText;
  }
}

let linkTo = (data) => {
  if (data.href) {
    let link = '"' + data.href + '"';
    let target = (data.openLinkInNewTab) ? 'new' : '';
    return <a href={link} target={target}>&gt;</a>
  } else {
    return <span></span>
  }
}

const MetricsView = (props) => {
  let blockClass = "metricsBlock " + props.data.theme ;
  const parentRef = React.createRef();
  let parent, rowCellContainer;

  useEffect(() => {
    rowCellContainer = parentRef.current.parentNode.parentNode;
    // rowCellContainer = parent.parentNode;
    rowCellContainer.className = rowCellContainer.className.includes('adjacent') ? rowCellContainer.className : rowCellContainer.className + ' adjacent';
    
    // rowCellContainer['style'] = {
    //   padding: 'inherit'
    // }
    
  });

  return <div className={blockClass} >
    <h3 className="number">{props.data.useThousands ? toThousands(props.data.number) : props.data.number}</h3>
    <h4 className="units">{props.data.unit || ''}</h4>
    <div className="text">{props.data.text || ''}</div>
    <div className="link">{linkTo(props.data)}</div>
    <div ref={parentRef}></div>
  </div>;
};

export default MetricsView;