import React, { useEffect, useRef, useState }  from 'react';
import './stitch-divider.less';



const StitchDividerView = (props) => {
  let ref = useRef();
  // const [stitch, setStitch] = useState("");

  const stitch = props.data.stitch;

  const blockClass = "stitchDividerBlock " + stitch;

  return <div className={blockClass}  >
    
  </div>;
};

export default StitchDividerView;