import React, { useEffect, useCallback, useRef, useState, Component }  from 'react';

import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {getGalleryImages} from '../../../../actions';
import { getBaseUrl } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";


import cx from 'classnames';
import './picture-gallery.less';

class PictureGallery extends Component {

  state = { 
    modalIsOpen: false,
    currentImage: 0 
  }

  toggleModal = () => {
    this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
  }

  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    getImagesFromPlone: PropTypes.func.isRequired,
    error: PropTypes.string,
    // gallery: PropTypes.arrayOf(
    //   PropTypes.shape({
    //     '@id': PropTypes.string,
    //     '@type': PropTypes.string,
    //     description: PropTypes.string,
    //     review_state: PropTypes.string,
    //     title: PropTypes.string,
    //   })
    // ),
    lodaded: PropTypes.bool,
    loading: PropTypes.bool,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Navigation
   */
   constructor(props) {
    super(props);

    this.blockClass = "pictureGalleryBlock";
    this.handleScroll = this.handleScroll.bind(this);
    this.ref = React.createRef();
    this.galleryRef = React.createRef();

    this.state = {
      hintSet: false,
      hideTip: false
    }

    const selector = this.props.selector != null && this.props.selector.trim().length > 0 ? this.props.selector : "gallery";

    this.props.getImagesFromPlone(selector);
  }

  handleScroll = e => {
    try {
      const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
      const pageSize = window.innerHeight;
      const pageDepth = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
      );
      const isHintVisible = this.isInViewport();
      const isGalleryVisible = this.isGalleryInViewport();

      if (isGalleryVisible) {
        if (!this.state.hintSet) {
          const top = this.galleryRef.current.getBoundingClientRect().top;
          this.setState({
            hintPosition: scrollY + top + 100,
            hintSet: true
          });
        } else {
          if (isHintVisible) {
            this.setState({hideTip : true})
          } 
        }
      }
      // console.log(`onScroll ${isGalleryVisible}, window.scrollY: ${scrollY} of ${pageDepth} with pages of ${pageSize}`);
      
    } catch (e) {
      console.log("EXCEPTION" + e);
    }
  } 
  
  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount = () => {
      window.removeEventListener('scroll', this.handleScroll);
  }

  isInViewport(offset = 0) {
    
    try {
      const bottom = this.ref.current.getBoundingClientRect().bottom;
      return this.isGalleryInViewport() && ((bottom + offset) >= 0) && ((bottom - offset) <= window.innerHeight );  
    } catch (e) {
      return false;
    }
    
  }

  isGalleryInViewport(offset = 0) {
    
    try {
      const top = this.galleryRef.current.getBoundingClientRect().top;
      return (top + offset) >= 0 && (top - offset) <= window.innerHeight ;  
    } catch (e) {
      console.error(e);
      return false;
    }
    
  }

  // hintPosition = (offset=0) => {
  //   try {
  //     const hintpos = offset + 100
  //     if (this.galleryRef.current.getBoundingClientRect().top < 20) {
  //       this.setState({
  //         hintPosition: hintpos, 
  //         hintSet: true
  //       });
  //       console.log("===========");
  //       console.log("SET POS TO " + offset + " + 100 = " + hintpos);
  //     }
  //     // return this.galleryRef.current.getBoundingClientRect().top + 600;
  //   } catch(e) {
  //     console.log(e);
  //     // return -600;
  //   }
  // }

  photoCount = () => {
    return parseInt(this.props.photoCount) || 16;
  }

  sliceSize = () => {
    const size = Math.min(this.props.images.length, this.photoCount());
    return (size >=0 ) ? size : 0;
  }

  galleryImages = () => {
    return this.props.images.slice(0, this.sliceSize()) || []; 
  }

  setCurrentImage = (index) => {
    // console.log("setting state to " + index);
    this.setState(state => ({ currentImage: index }));
  }

  openLightbox = (event, { photo, index }) => {
    this.setCurrentImage(index);
    this.toggleModal();
  };

  closeLightbox = () => {
    this.setCurrentImage(0);
    this.toggleModal();
  };

  getColorSetting = () => {
    try {
      return this.props.data.blackAndWhite ? "blackAndWhite pictureGalleryBlock" : "color pictureGalleryBlock";
    } catch(e) {
      return "color";
    }
  }


  imageRenderer = ({ index, left, top, key, photo }) => {
    const wrapperStyle = {
      top: top,
      left: left,
      position: "absolute",
      overflow: "hidden"
    };

    return (

      <div className="imgWrapper" style={wrapperStyle}>
        <img
          alt={photo.title}        
          {...photo}
        />
    </div>
    )
  ;
  }
      

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
   render() {
    const modalIsOpen  = this.state.modalIsOpen;
    const classString = this.state.hideTip ? "ColourFullScreenHint discreet"  : "ColourFullScreenHint";

    return (
      // <Gallery photos={picsumPhotos} />

      ((this.galleryImages().length > 0) ?
        <div className={this.getColorSetting()} ref={this.galleryRef} > 
        
        {/* <Gallery photos={this.galleryImages()} renderImage={this.imageRenderer} onClick={this.openLightbox} direction={"column"} columns={3} /> */}
        <Gallery photos={this.galleryImages()} targetRowHeight={500} onClick={this.openLightbox} />

        <div className={classString} ref={this.ref} style={{top: this.state.hintPosition}}>
          <div>Click images for full screen <span className="highlight">colour</span> gallery</div>
        </div>

        <ModalGateway>
          {modalIsOpen ? (
            <Modal onClose={this.closeLightbox}>
              <Carousel
                currentIndex={this.currentImage}
                views={this.galleryImages().map(x => ({
                  ...x,
                  srcset: x.src,
                  caption: x.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
      : <div className="emptyGallery"/>)

    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { error, loaded, loading, images } = state.imageGallery || {error: "", loaded: false, loading: false, images: []};
  const imageData = images.map(image => {
      let rObj = {}
      rObj["title"] = image.image.title;
      rObj["src"] = image.image.download;
      rObj["width"] = image.image.width;
      rObj["height"] = image.image.height;
      rObj["direction"] = "column";
      return rObj;
  });



  // console.log("=========== mapStateToProps ============");
  // console.log(imageData);
  // console.log("=========== END mapStateToProps ============");

  const propsStruct = {
    ...ownProps.data,
    images: imageData,
    loaded: loaded,
    loading: loading,
    error: error,
  }

  return propsStruct;
}

const mapDispatchToProps = {
  // ... normally is an object full of action creators
  getImagesFromPlone: getGalleryImages
}

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PictureGallery);

// 3. Use setState to store activeFilter (props are RO)
