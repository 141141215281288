const IMetricsSchema = {
    title: 'Heading and Lead',
  
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'number',
          'unit',
          'useThousands',
          'text',
          'theme'
        ],
      },
    ],
  
    properties: {
      number: {
        title: 'Number',
        description: 'Type metric number (e.g. 300000)',
        widget: 'text',
      },

      unit: {
        title: 'Unit',
        description: 'Insert unit for metric (e.g. units/month)',
        widget: 'text',
      },

      useThousands: {
        title: 'Use Thousands',
        description: 'Abbreviate Numbers (e.g. 300K)',
        type: 'boolean',
        // widget: 'checkbox',
      },

      text: {
        title: 'Text',
        description: 'Insert text',
        widget: 'textarea',
      },

      theme: {
        title: 'Theme',
        description: 'Choose your theme',
        type: 'choice',
        choices: [['default', 'default'], ['lighter', 'lighter'], ['light', 'light'], ['dark', 'dark'], ['darker', 'darker']]
        // vocabulary: 'volto.services.vocabularies.themes'
      },

      // align: {
      //   title: 'Alinment',
      //   description: 'Left, Right, Centre',
      //   type: 'boolean',
      // },
    },
  
    required: ['number', 'unit'],
  };
  
  export default IMetricsSchema;