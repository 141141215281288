import { tSTypeAliasDeclaration } from '@babel/types';
import React, { useEffect, useRef, useState, Component }  from 'react';
import { connect } from 'react-redux';
import {getFooterLinks} from '../../../../actions';

const MAX_LINKS = 6;
let Base64 = {_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",encode:function(e){var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},decode:function(e){var t="";var n,r,i;var s,o,u,a;var f=0;e=e.replace(/[^A-Za-z0-9+/=]/g,"");while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++));o=this._keyStr.indexOf(e.charAt(f++));u=this._keyStr.indexOf(e.charAt(f++));a=this._keyStr.indexOf(e.charAt(f++));n=s<<2|o>>4;r=(o&15)<<4|u>>2;i=(u&3)<<6|a;t=t+String.fromCharCode(n);if(u!=64){t=t+String.fromCharCode(r)}if(a!=64){t=t+String.fromCharCode(i)}}t=Base64._utf8_decode(t);return t},_utf8_encode:function(e){e=e.replace(/rn/g,"n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t},_utf8_decode:function(e){var t="";var n=0;var r=c1=c2=0;while(n<e.length){r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r);n++}else if(r>191&&r<224){c2=e.charCodeAt(n+1);t+=String.fromCharCode((r&31)<<6|c2&63);n+=2}else{c2=e.charCodeAt(n+1);c3=e.charCodeAt(n+2);t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63);n+=3}}return t}}
const shuffle = v=>[...v].sort(_=>Math.random()-.5).join('');

class ContentMirrorFooterView extends Component {
    /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Navigation
   */
   constructor(props) {
    super(props);

    // select all pages
    const pageFilter = "";

    this.props.getPagesFromPlone(pageFilter);
    this.state = {  
      filter: "show-all"
    };
  }

  hashCode = s => s.split('').reduce((a,b)=>{a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);

  generateKey = (seed) => {
    
    let d = new Date();
    let time = d.getTime();
    let key = seed + "." + Math.floor(Math.random() * 10000) + "." + time;
    let b64key = Base64.encode(shuffle(key));
    // console.log("Key: " + key + ", b64: " + b64key);
    return b64key;
  }

  getLabels() {
    // const orderedLinks = ["textiles", "footwear", "homeware", "insights", "about-us"]

    // let compare = (a, b) => {
    //     let mysearch = "" + a;
    //     // console.log("Comparing " + typeof(a) + " with " + typeof(b) + `.${mysearch}.`);
    //     let posA = orderedLinks.indexOf(a);
    //     let posB = orderedLinks.indexOf(b);
        
    //     console.log(a + " < " + b + "; posA="+posA + ", posB="+posB);
    //     if (posA == -1) {
    //         return (posB == -1) ? a<b : 1
    //     } else {
    //         return (posB == -1) ? -1 : posA-posB 
    //     }

    // }

      let htmlData = this.props.footerLinks.map((p) => {
            return  (
                <div className="footerCategory">
                    <h4 className='footerSection' >
                         <a href={p["@id"]} >
                             {p.title}
                         </a>
                     </h4>
                     <ul>
                         {
                            p.items.map((sp) => {
                             return (
                                <li className='footerSection' >
                                    <a href={sp["@id"]} >
                                        {sp.title}
                                    </a>
                                </li>
                            )
                         }).slice(0, MAX_LINKS)
                         }
                     </ul> 
                </div>     
            );
      });

    //   let htmlData = Object.keys(this.props.footerLinks).filter(x => {
    //       // below we exclude those marked explicity for exclusion and those that do not have children (which means they are like the home page, not a section)
    //       return (this.props.footerLinks[x]["exclude"] == false) && (Object.keys(this.props.footerLinks[x]).length > 4);
    //     }).sort(compare).map((key, index) => {
    //         // console.log("mapping " + key + " -> " + this.props.footerLinks[key]);
    //         const sectionId = "footerCategory-" + key;
    //         let sectionUrl = this.props.footerLinks[key]['url'];
    //         let sectionKey = this.generateKey(sectionUrl);
    //         let headerKey = "h4" + sectionKey;


    //         // console.log("GENERATED KEY = " + sectionKey);

    //         let subllinks = Object.keys(this.props.footerLinks[key]).filter(x => this.props.footerLinks[key][x]["exclude"] == false).map((subkey, index) => {
    //             let sublinkUrl = this.props.footerLinks[key][subkey]['url'];
    //             let sublinkKey = this.generateKey(sublinkUrl);
    //             return <li className='footerSection' key={sublinkKey}>
    //                     <a href={this.props.footerLinks[key][subkey]['url']} >
    //                         {this.props.footerLinks[key][subkey]["title"]}
    //                     </a>
    //                 </li>

    //         }).slice(0, MAX_LINKS);

    //     return <div className="footerCategory" id={sectionId} key={sectionKey} >
    //                 <h4 className='footerSection' key={headerKey}>
    //                     <a href={this.props.footerLinks[key]['url']} >
    //                         {this.props.footerLinks[key]["title"]}
    //                     </a>
    //                 </h4>
    //                 <ul>
    //                     {subllinks}
    //                 </ul>    
    //            </div>
    //   });

      return htmlData;
      
    // return this.props.footerLinks.forEach( (value, key, map) => {
    //     <h2>{key}</h2>
    // });

  }

  render() {
    return  (
        <div className="footerCategories">
            {this.getLabels()}            
        </div>
    );
  }  
}

const mapStateToProps = (state, ownProps) => {
    // console.log("CONTENT MIRROR MSTP");
    // console.log(state);
    const { error, loaded, loading, pages } = state.footer_links || {error: "", loaded: false, loading: false, pages: []};

    // console.log(">>>>>>>>>>>>>> FOOTER mapStateToProps");
    // const pageData = pages.map(page => {
    //     let rObj = {}
    //     rObj["title"] = page.title;
    //     rObj["description"] = page.description;
    //     try {
    //         rObj["url"] = (new URL(page.id)).pathname;
    //     } catch (e) {
    //         // console.log(e);
    //         rObj["url"] = page.id;
    //     }
        
    //     rObj["exclude"] = page.exclude_from_nav;
    //     return rObj;
    // });

    const footerContent=pages.filter(p =>  p.items.length > 0);
    // console.log(pages);
    // console.log(pageData);

    // console.log("RAW PAGE DATA");
    // console.log(pageData);

    // objectPathArray is a string array with the 
    // names of the folders for an item. For example
    // item /home/child/grandchild results in 
    // objectPathArray=["home", "child", "grandchild"].
    // Also note that in all likelyhood the first element
    // is an empty string, hence the need to SHIFT the array
    // const toTree = (objectPathArray, item) => {

    //     if ((objectPathArray==undefined) || objectPathArray.length==0) {
    //         return {}
    //     }

    //     let head = objectPathArray[0];

    //     // SHOULD WE SHIFT????
    //     if (head.length == 0) {
    //         objectPathArray.shift();
    //         // Yes, redefine new HEAD
    //         head = objectPathArray[0];
    //     } 
        
    //     if (objectPathArray.length > 1) {
    //         const tail = objectPathArray.slice(1);
    //         const newDic = {}
    //         newDic[head] = toTree(tail, item);
    //         return newDic;
    //     } else {
    //         const newDic = {}
    //         newDic[head] = item;
    //         return newDic;
    //     }

    // }

    // const mergeTrees = (aggregatedTree, newTree) => {
    //     const is_dictionary = (obj) => {
    //         const knownTypes = ["undefined", "boolean", "number", "bigint", "string", "symbol", "function"];            
    //         if(!obj) return false;
    //         if(Array.isArray(obj)) return false;
    //         if(obj.constructor != Object) return false;
    //         if (knownTypes.includes(typeof(obj))) return false;
    //         return true;
    //     };

    //     let combinedResult = [aggregatedTree, newTree].reduce(function (r, o) {
            
    //         Object.keys(o).forEach(function (k) { 
    //             let newValue = {};
    //             if (is_dictionary(o[k])) {
    //                 if (r[k] == undefined) {
    //                     newValue = o[k]
    //                 } else {
    //                     newValue = mergeTrees(r[k], o[k]);
    //                 }
    //             } else {
    //                 newValue =  o[k];
    //             }
                
    //             r[k] = newValue; 
    //         });
    //         return r;
    //     }, {});

    //     return combinedResult;
    // }

    // let footerContent = [];
    // let footerContent = pageData.map((elem) => toTree(elem["url"].split("/"), elem)).reduce(mergeTrees, {}) ;
    // console.log("FOOTER CONTENT NEXT");
    // console.log(footerContent);

    const propsStruct = {
      ...ownProps.data,
      footerLinks: footerContent,
      loaded: loaded,
      loading: loading,
      error: error,
    }
  
    return propsStruct;
  }

const mapDispatchToProps = {
// ... normally is an object full of action creators
    getPagesFromPlone: getFooterLinks
}

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ContentMirrorFooterView);


// export default ContentMirrorFooterView;