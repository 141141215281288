const IFullWidthDividerSchema = {
    title: 'Full Width Divider',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'heading',
          'subheading',
          'highlight',
          'lede',
          'image',
          'link',
          'showCursor',
          'twoThirdsHeight',
          'textBackground',
          'blackText',
          'blackAndWhite'
        ],
      },
    ],

    properties: {
      heading: {
        title: 'Heading',
        description: 'Insert Heading Text',
        widget: 'text',
      },

      subheading: {
        title: 'Sub Heading',
        description: 'Insert Sub Heading Text',
        widget: 'text',
      },

      highlight: {
        title: 'Highlight',
        description: 'Insert Highlighted Text',
        widget: 'text',
      },

      lede: {
        title: 'Lede text',
        description: 'Insert Lede Text',
        widget: 'text',
      },

      image: {
        title: 'Image',
        description: 'Choose Background Image',
        widget: 'object_browser',
        selectableTypes : ['Image'],
        mode: 'link'
      },

      link: {
        title: 'Link',
        description: 'Insert Link',
        widget: 'url',
      },

      showCursor: {
        title: 'Show Cursor',
        description: 'Shows Overlay of Cursor Image',
        type: 'boolean',
        // widget: 'checkbox',
      },

      twoThirdsHeight: {
        title: '2/3 Height',
        description: 'Reduces Divider Height',
        type: 'boolean',
        // widget: 'checkbox',
      },

      textBackground: {
        title: 'Darker Background on Text',
        description: '',
        type: 'choice',
        choices: [['lighterBackground', 'Lighter Background'], ['darkerBackground', 'Darker Background']]
      },

      blackText: {
        title: 'Black Text',
        description: 'Changes heading and lede to black',
        type: 'boolean',
        // widget: 'checkbox',
      },

      blackAndWhite: {
        title: 'Black & White',
        description: 'Make Picture B&W',
        type: 'boolean',
      },


    },

    required: ['heading'],
  };

  export default IFullWidthDividerSchema;
