import React, { useState } from 'react';
// import PropTypes from 'prop-types';

import { Segment, Form, Accordion, Grid } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import tableSVG from '@plone/volto/icons/table.svg';

import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import AlignBlock from '@plone/volto/components/manage/Sidebar/AlignBlock';

import BackgroundHeadingAndLedeView from './BackgroundHeadingAndLedeView';
import HeadingAndLedeSchema from './HeadingAndLedeSchema';

import imageSVG from '@plone/volto/icons/image.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import upSVG from '@plone/volto/icons/up-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';
import ImageSizeWidget from '@plone/volto/components/manage/Blocks/Image/ImageSizeWidget';


import {
  CheckboxWidget,
  Icon,
  FormFieldWrapper,
  TextWidget,
  SidebarPortal, 
  Field, 
} from '@plone/volto/components';

const messages = defineMessages({
  Image: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  Origin: {
    id: 'Origin',
    defaultMessage: 'Origin',
  },
  AltText: {
    id: 'Alt text',
    defaultMessage: 'Alt text',
  },
  Align: {
    id: 'Alignment',
    defaultMessage: 'Alignment',
  },
  LinkTo: {
    id: 'Link to',
    defaultMessage: 'Link to',
  },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Open in a new tab',
  },
  NoImageSelected: {
    id: 'No image selected',
    defaultMessage: 'No image selected',
  },
  externalURL: {
    id: 'External URL',
    defaultMessage: 'External URL',
  },
  size: {
    id: 'Size',
    defaultMessage: 'Size',
  },
});

const BackgroundHeadingAndLedeEdit = (props) => {
  const { selected, onChangeBlock, block, data, intl, openObjectBrowser, required, resetSubmitUrl } = props;
  const [activeAccIndex, setActiveAccIndex] = useState(0);

  function handleAccClick(e, titleProps) {
    const { index } = titleProps;
    const newIndex = activeAccIndex === index ? -1 : index;

    setActiveAccIndex(newIndex);
  }

  return (
    <div>
      <SidebarPortal selected={selected}>
        {/* <Segment.Group raised="false">
          <header className="header pulled">
            <h2>
              <FormattedMessage id="Image" defaultMessage="Image" />
            </h2>
          </header>
        
          <Segment className="sidebar-metadata-container" secondary> */}
            <InlineForm
                icon={<Icon size="24px" name={tableSVG} />}
                schema={HeadingAndLedeSchema}
                title={HeadingAndLedeSchema.title}
                headerActions={<button onClick={() => {}}>Action</button>}
                footer={<div></div>}
                onChangeField={(id, value) => {
                  onChangeBlock(block, {
                    ...data,
                    [id]: value,
                  });
                }}
                formData={data}
              />
          {/* </Segment>   
          <Segment className="sidebar-metadata-container" secondary> */}
            <Accordion fluid styled className="form">
              <Accordion.Title
                active={activeAccIndex === 0}
                index={0}
                onClick={handleAccClick}
              >
                Link Settings
                {activeAccIndex === 0 ? (
                  <Icon name={upSVG} size="20px" />
                ) : (
                  <Icon name={downSVG} size="20px" />
                )}
              </Accordion.Title>
              <Accordion.Content active={activeAccIndex === 0}>
                <TextWidget
                  id="link"
                  title={intl.formatMessage(messages.LinkTo)}
                  required={false}
                  value={data.href}
                  icon={data.href ? clearSVG : navTreeSVG}
                  iconAction={
                    data.href
                      ? () => {
                          onChangeBlock(block, {
                            ...data,
                            href: '',
                          });
                        }
                      : () => openObjectBrowser({ mode: 'link' })
                  }
                  onChange={(name, value) => {
                    onChangeBlock(block, {
                      ...data,
                      href: value,
                    });
                  }}
                />
                <CheckboxWidget
                  id="openLinkInNewTab"
                  title={intl.formatMessage(messages.openLinkInNewTab)}
                  value={data.openLinkInNewTab ? data.openLinkInNewTab : false}
                  onChange={(name, value) => {
                    onChangeBlock(block, {
                      ...data,
                      openLinkInNewTab: value,
                    });
                  }}
                />
              </Accordion.Content>
            </Accordion> 
          {/* </Segment> 
          </Segment.Group>  */}
       </SidebarPortal> 

       <BackgroundHeadingAndLedeView {...props} /> 
    </div>
  );
};

export default BackgroundHeadingAndLedeEdit;