import React, { useEffect, useRef, useState }  from 'react';
import { useLocation } from 'react-router-dom';

const ScrollEffectsInsertion = (props) => {
    const ref = useRef();
    const [transparentHeader, setTransparentHeader] = useState(true);
    let location = useLocation();

    const isTablet = () => {
      try {
        const body = document.getElementsByTagName("body")[0];
        const bodyWidth = body.offsetWidth;
        return (bodyWidth>=768 && bodyWidth<1080);
      } catch (e) {
        return false;
      }
    }
    
    const isDesktop = () => {
      try {
        const body = document.getElementsByTagName("body")[0];
        const bodyWidth = body.offsetWidth;
        return (bodyWidth>=1080);
      } catch(e) {
        return false;
      }
    
    }

    useEffect(() => {
      const headerWrapper = document.getElementsByClassName("header-wrapper")[0];
      if (headerWrapper !== undefined) {
        if (transparentHeader) {        
          headerWrapper.classList.remove("scrolled");
        } else {
          headerWrapper.classList.add("scrolled");
        }
      }  
    }, [transparentHeader]);

    // Initialize GA4
    useEffect(() => {
        // Only run on client side
        if (__CLIENT__ /*&& (isDesktop() || isTablet())*/) {
            document.addEventListener("scroll", (event) => {
              // If the user has scrolled down 100px and we have a transparentHeader, set a non-transparentHeader
              (window.scrollY > 100) && setTransparentHeader(false);
              // If the user has scrolled above 100px and we have a non-transparentHeader, set a transparentHeader
              (window.scrollY <= 100) && setTransparentHeader(true);

              // console.log(`lastKnownScrollPosition=${window.scrollY}; transparentHeader=${transparentHeader}`)
            });
        }
      }, []);

    // Trigger GA execution if URL changes
    useEffect(() => {
        // console.log("ScrollEffectsInsertion URL Change: ")
        
      }, [location]);

    return(<div ref={ref} className="ScrollEffectsInsertion"></div>)

}


export default ScrollEffectsInsertion;