const IExternalLinkCardSchema = {
    title: 'Hero Banner',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'heading',
          'description',
          'image',
          'link',
          'theme',
          'placeTextLeft',
          'blackAndWhite'
        ],
      },
    ],

    properties: {

      heading: {
        title: 'Heading',
        description: 'Insert Heading Text',
        widget: 'text',
      },

      description: {
        title: 'Desciption',
        description: 'Insert Description Text',
        widget: 'text',
      },

      image: {
        title: 'Image',
        description: 'Choose Background Image',
        widget: 'object_browser',
        selectableTypes : ['Image'],
        mode: 'link'
      },

      link: {
        title: 'Link',
        description: 'Insert Link',
        widget: 'object_browser',
        selectableTypes : ['Page'],
        mode: 'link'
      },

      theme: {
        title: 'Theme',
        description: 'Choose your theme',
        type: 'choice',
        choices: [['default', 'default'], ['lighter', 'lighter'], ['light', 'light'], ['dark', 'dark'], ['darker', 'darker']]
        // vocabulary: 'volto.services.vocabularies.themes'
      },

      placeTextLeft: {
        title: 'Place Text Left',
        description: 'Inverts order of text & image',
        type: 'boolean',
      },

      blackAndWhite: {
        title: 'Black & White',
        description: 'Make Picture B&W',
        type: 'boolean',
      },


    },

    required: ['link'],
  };

  export default IExternalLinkCardSchema;
