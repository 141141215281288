/** Image Gallery actions.
* @module actions/content_types/get_pages
*/

import { GET_RELATED_PAGES } from '../../constants/ActionTypes';

/**
* Get FAQ items.
* @function getRelatedPages
* @returns {Object} Images action.
*/
export function getRelatedPages(tag) {
  return (tag.length > 0) ? {
    type: GET_RELATED_PAGES,
    filter: tag,
    request: {
      op: 'get',
      path: `/@search?portal_type=Document&Subject=${tag}&fullobjects=1`,
    },
 } : {
    type: GET_RELATED_PAGES,
    filter: null,
    request: {
      op: 'get',
      path: `/@search?portal_type=Document&fullobjects=1`,
    },
 };
}
