/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@package/config');
import eeacmsvoltoBlocksForm from '@eeacms/volto-blocks-form';
import eeacmsvoltoColumnsBlock from '@eeacms/volto-columns-block';
import eeacmsvoltoResizeHelper from '@eeacms/volto-resize-helper';
import eeacmsvoltoBlockStyle from '@eeacms/volto-block-style';
import gliscocustomBlocks from '@glisco/custom-blocks';

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [eeacmsvoltoBlocksForm, eeacmsvoltoColumnsBlock, eeacmsvoltoResizeHelper, eeacmsvoltoBlockStyle, gliscocustomBlocks];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
