import React, { useState } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';


import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import AlignBlock from '@plone/volto/components/manage/Sidebar/AlignBlock';

import ContactFormView from './ContactFormView';
import ContactFormSchema from './ContactFormSchema';

import imageSVG from '@plone/volto/icons/image.svg';


import {
  Icon,
  SidebarPortal,
} from '@plone/volto/components';


const ContactFormEdit = (props) => {
  const { selected, onChangeBlock, block, data, intl, openObjectBrowser, required, resetSubmitUrl } = props;
  const [activeFilter, setActiveFilter] = useState(0);

  console.log(`>>>>> EDIT FORM COMPONENT. Data follows`);
  console.log(data);
  console.log(`end of data`);
  return (
    <div>
      <SidebarPortal selected={selected}>
        {/* <InlineForm
            icon={<Icon size="24px" name={imageSVG} />}
            schema={ContactFormSchema}
            title={ContactFormSchema.title}
            headerActions={<button onClick={() => {}}>Action</button>}
            footer={<div></div>}
            onChangeField={(id, value) => {
              onChangeBlock(block, {
                ...data,
                [id]: value,
              });
            }}
            formData={data}
          /> */}

       </SidebarPortal>

       <ContactFormView {...props} />
    </div>
  );
};

export default ContactFormEdit;
