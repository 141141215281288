import React, { useEffect, useRef, useState }  from 'react';
import './icon-text.less';



const IconTextView = (props) => {
  let ref = useRef();
  const [config, setConfig] = useState();

  // const stitch = props.data.stitch;

  const blockClass = "iconTextBlock section "

  const imgUrl = () => {
    // console.log(JSON.stringify(props.data.image));

    let imagePath = "";
    try {
      // console.table(props.data);
      imagePath = props.data.image[0]["getURL"].replace(process.env.RAZZLE_API_PATH, "") + '/@@images/image.png';
    } catch (e) {
      console.log("Error in IMG URL in IconTextView: " + e.toString());
    }

    return imagePath;
  }

  // console.log("****************************************");
  // console.log(props.data.image);
  // console.log("****************************************");


  return <div className={blockClass}  >

    {/* <div className="textContainer">
      <h1>{props.data.heading}</h1>
      <h2 className="sub-heading">{props.data.subheading}</h2>
      <h2 className="highlight">{props.data.highlight}</h2>
      <div className="lede">{props.data.highlight}</div>
    </div> */}

      <div className="icon-text" >
        <div>
          <div className="noshadow padded-left icon-text-block">
            <div className="icon-text-image">
              <img src={imgUrl()} alt="img"></img>
            </div>
            <div className="icon-text-text">
              <h3>{props.data.heading}</h3>
              <div className="lede">{props.data.lede}</div>
            </div>
          </div>
        </div>
      </div>

  </div>;
};

export default IconTextView;
