const IHeadingAndLedeSchema = {
    title: 'Heading and Lead',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'heading',
          'subheading',
          'lede',
          'layout',
          'theme',
          'align',
        ],
      },
    ],

    properties: {
      heading: {
        title: 'Heading',
        description: 'Insert Heading Text',
        widget: 'text',
      },

      subheading: {
        title: 'Sub Heading',
        description: 'Insert Sub Heading Text',
        widget: 'text',
      },

      lede: {
        title: 'Lede text',
        description: 'Insert Lede Text',
        widget: 'text',
      },

      layout: {
        title: 'Layout',
        description: 'Choose your layout',
        type: 'choice',
        choices: [['stacked', 'stacked'], ['flow', 'flow']]
        // vocabulary: 'volto.services.vocabularies.themes'
      },

      theme: {
        title: 'Theme',
        description: 'Choose your theme',
        type: 'choice',
        choices: [['default', 'default'], ['lighter', 'lighter'], ['light', 'light'], ['dark', 'dark'], ['darker', 'darker']]
        // vocabulary: 'volto.services.vocabularies.themes'
      },

      align: {
        title: 'Alignment',
        description: 'Choose your text alignment',
        type: 'choice',
        choices: [['left', 'left'], ['center', 'center']]
      },
    },

    required: ['heading', 'lede'],
  };

  export default IHeadingAndLedeSchema;
