const IStitchDividerSchema = {
    title: 'Stitch Divider',
  
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'stitch'
        ],
      },
    ],
  
    properties: {
      stitch: {
        title: 'Stitch Style',
        description: '',
        type: 'choice',
        choices: [['dotted', 'Dotted'], ['diamond', 'Diamond'], ['slash', 'Slash']]
      },
    },
  
    required: ['stitch'],
  };
  
  export default IStitchDividerSchema;