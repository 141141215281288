import layouts from '@plone/volto/constants/Layouts';

/**
 * Layouts.
 * @module constants/Layouts
 */

 export default {
    ...layouts,
    default_page_view: 'My New View'
  };