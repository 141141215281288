import React, { useEffect, useRef, useState } from 'react';
import './svg-stats.less';
import $ from 'jquery';

// var jsdom = require ("jsdom");
// var $ = require ("jquery") (jsdom.jsdom (). createWindow ());

function whichTransitionEndEvent(){
  var t,
      el = document.createElement("fakeelement");

  var transitions = {
    "transition"      : "transitionend",
    "OTransition"     : "oTransitionEnd",
    "MozTransition"   : "transitionend",
    "WebkitTransition": "webkitTransitionEnd"
  }

  for (t in transitions){
    if (el.style[t] !== undefined){
      return transitions[t];
    }
  }
}

function whichAnimationEndEvent(){
  var t,
      el = document.createElement("fakeelement");

  var transitions = {
    "transition"      : "animationend",
    "OTransition"     : "oAnimationEnd",
    "MozTransition"   : "animationend",
    "WebkitTransition": "webkitAnimationEnd"
  }

  for (t in transitions){
    if (el.style[t] !== undefined){
      return transitions[t];
    }
  }
}

function isSafariOniOS() {
  try {
    if ((window != undefined) && (navigator != undefined)) {
      var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
      var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      // console.log("Safari: " + isSafari +"; iOS: " + iOS);
      return (isSafari && iOS);
  
    } else {
      return false;
    }
  } catch (e) {
    // console.log("ERROR! ERROR! ERROR! ERROR! ERROR! ERROR! ERROR! ERROR! ERROR! ERROR! ")
    // console.log(e);
    // console.log("++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++")
    return false;
  }
  
  
}



const SVGStatsView = (props) => {
  // console.log("************************* SVGStatsView SVGStatsView SVGStatsView");
  // console.log(props);
  let blockclassName = "svgStatsBlock";
  const [activeTab, setActiveTab] = useState(0);
  const [activatedTabs, setActivatedTabs] = useState([]);
  let safariAwareClass = isSafariOniOS() ? "svg safari-ios" :  "svg";

  function tabClassifier(tab) {
    return (tab==activeTab) ? 'active' : 'inactive';
  }

  function enableTab(tab) {
    // var transitionEndEvent = whichTransitionEndEvent();
    // var animationEndEvent = whichAnimationEndEvent();
    const animationIds = ["#svg-drawing-one", "#svg-drawing-two", "#svg-drawing-three"];
    // const firstPaths = $("#svg-drawing-one").find("path").length;
    // const secondPaths = $("#svg-drawing-two").find("path").length;
    // const thirdPaths = $("#svg-drawing-three").find("path").length;

    setActiveTab(tab);

    if (!activatedTabs.includes(tab)) {
      setActivatedTabs([
                        ...activatedTabs, 
                        tab
                      ]);
      
      $(animationIds[tab]).removeClass("invisible");
      $(animationIds[tab]).addClass("visible");
      $(animationIds[tab]).css("display", "block");
      $(animationIds[tab]).addClass("stitch-animation");                  

    } else {
      // console.log(">>>>> Tab " + tab + " already processed");
    }

  }

  let textileText = () => {
    try {
      return (props.data.textileText.length != 0) ? 
            props.data.textileText : 
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempor ante et lacinia tristique. Maecenas leo turpis, viverra non posuere ac, maximus eget elit. "
    } catch (e) {
      return "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempor ante et lacinia tristique. Maecenas leo turpis, viverra non posuere ac, maximus eget elit. "
    }
    
  }

  let footwearText = () => {
    try {
      return (props.data.footwearText.length != 0) ? 
            props.data.footwearText : 
            "Cras eget pulvinar ex. Cras commodo mi est, nec euismod neque vestibulum quis. Proin rutrum dignissim nulla sit amet tristique. Nam eget dignissim eros."
    } catch(e) {
      return "Cras eget pulvinar ex. Cras commodo mi est, nec euismod neque vestibulum quis. Proin rutrum dignissim nulla sit amet tristique. Nam eget dignissim eros."
    }
    
  }

  let homewareText = () => {
    try {
      return (props.data.homewareText.length != 0) ? 
      props.data.homewareText : 
      "Cras eget pulvinar ex. Cras commodo mi est, nec euismod neque vestibulum quis. Proin rutrum dignissim nulla sit amet tristique. Nam eget dignissim eros."
    } catch (e) {
      return "Cras eget pulvinar ex. Cras commodo mi est, nec euismod neque vestibulum quis. Proin rutrum dignissim nulla sit amet tristique. Nam eget dignissim eros."
    }
  }
  
  useEffect(() => {
    // console.log("in use effect SVG Stats View + " + activeTab);

    if (isSafariOniOS()) {
      $("div.svg").addClass("safari-ios");
    }    
  });

  return <div className={`${blockclassName}`} >
  <nav className="animation-nav">
    <div id="tab1" className={`tab ${tabClassifier(0)}-tab`}>
      <a onClick={() => enableTab(0)}>Clothing</a>
    </div>
    <div id="tab2" className={`tab ${tabClassifier(1)}-tab`}>
    <a onClick={() => enableTab(1)}>Shoes</a>
    </div>
    <div id="tab3" className={`tab ${tabClassifier(2)}-tab`}>
    <a onClick={() => enableTab(2)}>Homewear</a>
    </div>
  </nav>

  <div className={`tab-content-wrapper activeTab${activeTab}`}>
    <div className={`tab-content ${tabClassifier(0)}`}>
      <div className={safariAwareClass}>
        <svg id="svg-drawing-one" className="svg-drawing filled-svg visible" xmlns="http://www.w3.org/2000/svg" width="575.376" height="251.681" viewBox="0 0 575.376 251.681">
            <g id="Group_117" data-name="Group 117" transform="translate(-509.029 -1079.397)">
              <path className="stitch-animation first-animation tab1animation" id="Path_116" data-name="Path 116" d="M-1705.819-1912.68l-11.994-20.715h82.436l-11.758,20.715Z" transform="translate(2342.272 3027.733)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_116-dashed" data-name="Path 116 Dashed" d="M-1705.819-1912.68l-11.994-20.715h82.436l-11.758,20.715Z" transform="translate(2342.272 3027.733)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="stitch-animation first-animation tab1animation tab2animation" id="Path_117" data-name="Path 117" d="M-1640.062-1910.79l13.836-23.438s4.6,4.178,4.69,9.456,0,61.214,0,61.214l-18.526-8.535Z" transform="translate(2335.167 3028.566)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
              <path className="to-animate-dashed tab2animation-dashed" id="Path_117-dashed" data-name="Path 117 Dashed" d="M-1640.062-1910.79l13.836-23.438s4.6,4.178,4.69,9.456,0,61.214,0,61.214l-18.526-8.535Z" transform="translate(2335.167 3028.566)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="stitch-animation first-animation tab1animation" id="Path_139" data-name="Path 139" d="M11.994,20.716,0,0H82.436L70.677,20.716Z" transform="translate(706.894 1243.003) rotate(-180)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_139-dashed" data-name="Path 139 Dashed" d="M11.994,20.716,0,0H82.436L70.677,20.716Z" transform="translate(706.894 1243.003) rotate(-180)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path id="Path_118" data-name="Path 118" d="M0,23.439,13.836,0s4.6,4.179,4.69,9.457,0,61.214,0,61.214L0,62.135Z" transform="translate(635.947 1243.003) rotate(-180)" fill="none" stroke="#757575" strokeWidth="0.5" strokeDasharray="2 2" opacity="0.503"/>
              <path id="Path_119" data-name="Path 119" d="M-1621.494-1910.79l-13.836-23.438s-4.6,4.178-4.69,9.456,0,61.214,0,61.214l18.526-8.535Z" transform="translate(2257.439 3028.566)" fill="none" stroke="#757575" strokeWidth="0.5" strokeDasharray="2 2" opacity="0.503"/>

              <path className="stitch-animation first-animation tab1animation tab2animation" id="Path_120" data-name="Path 120" d="M-1640.062-1887l13.836,23.439s4.6-4.178,4.69-9.457,0-61.214,0-61.214l-18.526,8.535Z" transform="translate(2335.167 3106.56)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
              <path className="to-animate-dashed tab2animation-dashed" id="Path_120-dashed" data-name="Path 120 Dashed" d="M-1640.062-1887l13.836,23.439s4.6-4.178,4.69-9.457,0-61.214,0-61.214l-18.526,8.535Z" transform="translate(2335.167 3106.56)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="stitch-animation first-animation tab1animation" id="Path_121" data-name="Path 121" d="M-1722.394-1858.281l19.273-8.805h57.3l19.353,8.805-19.353,9.217h-57.3Z" transform="translate(2340.108 3027.028)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_121-dashed" data-name="Path 121 Dashed" d="M-1722.394-1858.281l19.273-8.805h57.3l19.353,8.805-19.353,9.217h-57.3Z" transform="translate(2340.108 3027.028)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="stitch-animation first-animation tab1animation tab2animation" id="Path_122" data-name="Path 122" d="M-1705.819-1912.68l-11.994-20.715h82.436l-11.758,20.715Z" transform="translate(2455.279 3028.016)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
              <path className="to-animate-dashed tab2animation-dashed" id="Path_122-dashed" data-name="Path 122 Dashed" d="M-1705.819-1912.68l-11.994-20.715h82.436l-11.758,20.715Z" transform="translate(2455.279 3028.016)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="stitch-animation first-animation tab1animation tab2animation" id="Path_123" data-name="Path 123" d="M-1705.819-1912.68l-11.994-20.715h82.436l-11.758,20.715Z" transform="translate(2568.287 3026.914)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
              <path className="to-animate-dashed tab2animation-dashed" id="Path_123-dashed" data-name="Path 123 Dashed" d="M-1705.819-1912.68l-11.994-20.715h82.436l-11.758,20.715Z" transform="translate(2568.287 3026.914)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="stitch-animation first-animation tab1animation tab2animation" id="Path_124" data-name="Path 124" d="M-1640.062-1910.79l13.836-23.438s4.6,4.178,4.69,9.456,0,61.214,0,61.214l-18.526-8.535Z" transform="translate(2448.175 3028.849)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
              <path className="to-animate-dashed tab2animation-dashed" id="Path_124-dashed" data-name="Path 124 Dashed" d="M-1640.062-1910.79l13.836-23.438s4.6,4.178,4.69,9.456,0,61.214,0,61.214l-18.526-8.535Z" transform="translate(2448.175 3028.849)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="stitch-animation first-animation tab1animation tab2animation" id="Path_125" data-name="Path 125" d="M-1640.062-1910.79l13.836-23.438s4.6,4.178,4.69,9.456,0,61.214,0,61.214l-18.526-8.535Z" transform="translate(2561.183 3027.747)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
              <path className="to-animate-dashed tab2animation-dashed" id="Path_125-dashed" data-name="Path 125 Dashed" d="M-1640.062-1910.79l13.836-23.438s4.6,4.178,4.69,9.456,0,61.214,0,61.214l-18.526-8.535Z" transform="translate(2561.183 3027.747)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="stitch-animation first-animation tab1animation" id="Path_126" data-name="Path 126" d="M0,82.519s20.759,16.7,19.427,16.3,4.741-82.256,4.741-82.256L4.428,0Z" transform="translate(1081.246 1235.29) rotate(140)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_126-dashed" data-name="Path 126 Dashed" d="M0,82.519s20.759,16.7,19.427,16.3,4.741-82.256,4.741-82.256L4.428,0Z" transform="translate(1081.246 1235.29) rotate(140)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="stitch-animation first-animation tab1animation" id="Path_127" data-name="Path 127" d="M0,16.308S20.759-.388,19.427.007s4.741,82.256,4.741,82.256L4.428,98.828Z" transform="translate(1017.721 1179.082) rotate(-140)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_127-dashed" data-name="Path 127 Dashed" d="M0,16.308S20.759-.388,19.427.007s4.741,82.256,4.741,82.256L4.428,98.828Z" transform="translate(1017.721 1179.082) rotate(-140)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="stitch-animation first-animation tab1animation" id="Path_128" data-name="Path 128" d="M0,27,14.943,0s4.966,4.813,5.065,10.892,0,70.506,0,70.506L0,71.567Z" transform="translate(1006.774 1253.751) rotate(-180)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_128-dashed" data-name="Path 128 Dashed" d="M0,27,14.943,0s4.966,4.813,5.065,10.892,0,70.506,0,70.506L0,71.567Z" transform="translate(1006.774 1253.751) rotate(-180)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="stitch-animation first-animation tab1animation" id="Path_129" data-name="Path 129" d="M-1620.009-1907.232l-14.942-27s-4.966,4.813-5.065,10.892,0,70.506,0,70.506l20.008-9.831Z" transform="translate(2626.782 3016.747)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_129-dashed" data-name="Path 129 Dashed" d="M-1620.009-1907.232l-14.942-27s-4.966,4.813-5.065,10.892,0,70.506,0,70.506l20.008-9.831Z" transform="translate(2626.782 3016.747)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="stitch-animation first-animation tab1animation tab2animation" id="Path_140" data-name="Path 140" d="M11.994,20.716,0,0H82.436L70.677,20.716Z" transform="translate(819.901 1243.286) rotate(-180)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
              <path className="to-animate-dashed tab2animation-dashed" id="Path_140-dashed" data-name="Path 140 Dashed" d="M11.994,20.716,0,0H82.436L70.677,20.716Z" transform="translate(819.901 1243.286) rotate(-180)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="stitch-animation first-animation tab1animation tab2animation" id="Path_138" data-name="Path 138" d="M11.994,20.716,0,0H82.436L70.677,20.716Z" transform="translate(932.909 1242.184) rotate(-180)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
              <path className="to-animate-dashed tab2animation-dashed" id="Path_138-dashed" data-name="Path 138 Dashed" d="M11.994,20.716,0,0H82.436L70.677,20.716Z" transform="translate(932.909 1242.184) rotate(-180)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="stitch-animation first-animation tab1animation tab2animation" id="Path_130" data-name="Path 130" d="M0,23.439,13.836,0s4.6,4.179,4.69,9.457,0,61.214,0,61.214L0,62.135Z" transform="translate(748.954 1243.286) rotate(-180)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
              <path className="to-animate-dashed tab2animation-dashed" id="Path_130-dashed" data-name="Path 130 Dashed" d="M0,23.439,13.836,0s4.6,4.179,4.69,9.457,0,61.214,0,61.214L0,62.135Z" transform="translate(748.954 1243.286) rotate(-180)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="stitch-animation first-animation tab1animation" id="Path_131" data-name="Path 131" d="M0,23.439,13.836,0s4.6,4.179,4.69,9.457,0,61.214,0,61.214L0,62.135Z" transform="translate(861.962 1242.184) rotate(-180)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_131-dashed" data-name="Path 131 Dashed" d="M0,23.439,13.836,0s4.6,4.179,4.69,9.457,0,61.214,0,61.214L0,62.135Z" transform="translate(861.962 1242.184) rotate(-180)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="stitch-animation first-animation tab1animation tab2animation" id="Path_132" data-name="Path 132" d="M-1621.494-1910.79l-13.836-23.438s-4.6,4.178-4.69,9.456,0,61.214,0,61.214l18.526-8.535Z" transform="translate(2370.448 3028.849)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
              <path className="to-animate-dashed tab2animation-dashed" id="Path_132-dashed" data-name="Path 132 Dashed" d="M-1621.494-1910.79l-13.836-23.438s-4.6,4.178-4.69,9.456,0,61.214,0,61.214l18.526-8.535Z" transform="translate(2370.448 3028.849)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="stitch-animation first-animation tab1animation" id="Path_133" data-name="Path 133" d="M-1621.494-1910.79l-13.836-23.438s-4.6,4.178-4.69,9.456,0,61.214,0,61.214l18.526-8.535Z" transform="translate(2483.456 3027.747)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_133-dashed" data-name="Path 133 Dashed" d="M-1621.494-1910.79l-13.836-23.438s-4.6,4.178-4.69,9.456,0,61.214,0,61.214l18.526-8.535Z" transform="translate(2483.456 3027.747)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="stitch-animation first-animation tab1animation tab2animation" id="Path_134" data-name="Path 134" d="M-1640.062-1887l13.836,23.439s4.6-4.178,4.69-9.457,0-61.214,0-61.214l-18.526,8.535Z" transform="translate(2448.175 3106.843)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
              <path className="to-animate-dashed tab2animation-dashed" id="Path_134-dashed" data-name="Path 134 Dashed" d="M-1640.062-1887l13.836,23.439s4.6-4.178,4.69-9.457,0-61.214,0-61.214l-18.526,8.535Z" transform="translate(2448.175 3106.843)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="stitch-animation first-animation tab1animation tab2animation" id="Path_135" data-name="Path 135" d="M-1640.062-1887l13.836,23.439s4.6-4.178,4.69-9.457,0-61.214,0-61.214l-18.526,8.535Z" transform="translate(2561.183 3105.741)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
              <path className="to-animate-dashed tab2animation-dashed" id="Path_135-dashed" data-name="Path 135 Dashed" d="M-1640.062-1887l13.836,23.439s4.6-4.178,4.69-9.457,0-61.214,0-61.214l-18.526,8.535Z" transform="translate(2561.183 3105.741)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path id="Path_136" data-name="Path 136" d="M-1722.394-1858.281l19.273-8.805h57.3l19.353,8.805-19.353,9.217h-57.3Z" transform="translate(2453.115 3027.312)" fill="none" stroke="#757575" strokeWidth="0.5" strokeDasharray="2 2" opacity="0.503"/>

              <path className="tab2animation" id="Path_137" data-name="Path 137" d="M-1722.394-1858.281l19.273-8.805h57.3l19.353,8.805-19.353,9.217h-57.3Z" transform="translate(2566.122 3026.209)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.5" strokeDasharray="2 2" opacity="0.503"/>
              <path className="tab2animation-dashed" id="Path_137" data-name="Path 137" d="M-1722.394-1858.281l19.273-8.805h57.3l19.353,8.805-19.353,9.217h-57.3Z" transform="translate(2566.122 3026.209)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <text className="stitch-animation first-animation tab1animation" id="_" data-name="+" transform="translate(512.029 1204.397)" fill="none" stroke="#fff" strokeWidth="3" strokeDasharray="0.5 0.5" fontSize="116" fontFamily="OriyaSangamMN, Oriya Sangam MN" letterSpacing="0.01em"><tspan x="0" y="0">+</tspan></text>
              <text className="to-animate-dashed" id="_dashed" data-name="+ Dashed" transform="translate(512.029 1204.397)" fill="none" stroke="#fff" strokeWidth="3" strokeDasharray="0.5 0.5" fontSize="116" fontFamily="OriyaSangamMN, Oriya Sangam MN" letterSpacing="0.01em"><tspan x="0" y="0">+</tspan></text>
              <text id="Vase_of_Flowers_STYLE:STYLE2_TAG:H5_" data-name="Vase of Flowers [STYLE:STYLE2][TAG:H5]" transform="translate(828.43 1310.078)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" strokeDasharray="0.5 0.5" fontSize="53" fontFamily="OriyaSangamMN-Bold, Oriya Sangam MN" fontWeight="700"><tspan x="-212.076" y="0">UNITS/MONTH</tspan></text>

            </g>
        </svg>
      </div>
      <div className="tab-content-text">
        <div id="text1" className="svg-text">
          <div className="white-text">
            <p>{textileText()}</p>
          </div>
          <div className="red-text">
            <a href="./textiles/">
              <div className="link-wrapper">
                <div className="link-text">See our textiles</div>
                <div className="link-arrow"><span className="arrow-icon"></span></div>
              </div>  
            </a>
          </div>
        </div>
       </div> 
    </div>

    <div className={`tab-content ${tabClassifier(1)}`}>
      <div className="svg">
        <svg id="svg-drawing-two" className="svg-drawing second-svg invisible" xmlns="http://www.w3.org/2000/svg" width="349.821" height="134.148" viewBox="0 0 349.821 134.148">
          <g id="Component_45_1" data-name="Component 45 – 1" transform="translate(1.5 1.558)">
            <path className="sapatilha-svg" id="Path_169" data-name="Path 169" d="M-202.723,1845.657H74.7s64.053-5.928,67.744-21.374-.048-22.458,0-22.8-6.076-25.112-54.4-18.839c-16.742-6.3-33.527-13.082-41.476-15.455.316-.833-3.474-6.892-12.934-5.45-2.643-10.471-10.033-3.233-13.353-8.2s-11.5-3.623-13.837-8.6-7.3-.92-11.64-6.917-9.653-7.23-12.872-7.209,3.023-8.945-14.209-8.912c-27.73-15.816-40.828,0-40.828,0s-6.605-11.088-14-6-11.061,21.221-17.352,24.946c-30.265,16.547-54.507-6.539-55.273-7.926s-4.163-18.382-18.21-13.47-11.447,27.917-17.537,36.816-7.245,26.378-7.245,26.378Z" transform="translate(202.723 -1714.566)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
            <path className="to-animate-dashed"  id="Path_169" data-name="Path 169" d="M-202.723,1845.657H74.7s64.053-5.928,67.744-21.374-.048-22.458,0-22.8-6.076-25.112-54.4-18.839c-16.742-6.3-33.527-13.082-41.476-15.455.316-.833-3.474-6.892-12.934-5.45-2.643-10.471-10.033-3.233-13.353-8.2s-11.5-3.623-13.837-8.6-7.3-.92-11.64-6.917-9.653-7.23-12.872-7.209,3.023-8.945-14.209-8.912c-27.73-15.816-40.828,0-40.828,0s-6.605-11.088-14-6-11.061,21.221-17.352,24.946c-30.265,16.547-54.507-6.539-55.273-7.926s-4.163-18.382-18.21-13.47-11.447,27.917-17.537,36.816-7.245,26.378-7.245,26.378Z" transform="translate(202.723 -1714.566)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            <path className="sapatilha-svg" id="Path_170" data-name="Path 170" d="M-202.723,1898.619H-76.495s44.52,10.878,77.757,10.05,103.257-1.983,103.257-1.983,30.7-.928,37.049-17.28" transform="translate(202.723 -1803.065)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
            <path className="to-animate-dashed" id="Path_170" data-name="Path 170" d="M-202.723,1898.619H-76.495s44.52,10.878,77.757,10.05,103.257-1.983,103.257-1.983,30.7-.928,37.049-17.28" transform="translate(202.723 -1803.065)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            <path className="sapatilha-svg" id="Path_171" data-name="Path 171" d="M239.88,1825.692s5.453-29.128-18.795-34.985-60.618,19.283-161.353-61.2" transform="translate(69.876 -1722.13)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
            <path className="to-animate-dashed" id="Path_171" data-name="Path 171" d="M239.88,1825.692s5.453-29.128-18.795-34.985-60.618,19.283-161.353-61.2" transform="translate(69.876 -1722.13)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            <path className="sapatilha-svg" id="Path_172" data-name="Path 172" d="M138.761,1730.025s5.227,6.993-2.318,13.353,1.812,10.737,4.6,6.27a117.176,117.176,0,0,0,6.275-12.78" transform="translate(36.095 -1722.392)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
            <path className="to-animate-dashed"id="Path_172" data-name="Path 172" d="M138.761,1730.025s5.227,6.993-2.318,13.353,1.812,10.737,4.6,6.27a117.176,117.176,0,0,0,6.275-12.78" transform="translate(36.095 -1722.392)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            <path className="sapatilha-svg" id="Path_173" data-name="Path 173" d="M140.486,1732.973s3.5,5.538-4.042,11.9,1.812,10.736,4.6,6.269a115.6,115.6,0,0,0,6.211-12.6" transform="translate(49.783 -1715.488)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
            <path className="to-animate-dashed" id="Path_173" data-name="Path 173" d="M140.486,1732.973s3.5,5.538-4.042,11.9,1.812,10.736,4.6,6.269a115.6,115.6,0,0,0,6.211-12.6" transform="translate(49.783 -1715.488)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            <path className="sapatilha-svg" id="Path_174" data-name="Path 174" d="M141.043,1735.325s2.945,4.376-4.6,10.736,1.812,10.736,4.6,6.269,7.606-13.777,7.606-13.777" transform="translate(60.154 -1708.778)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
            <path className="to-animate-dashed" id="Path_174" data-name="Path 174" d="M141.043,1735.325s2.945,4.376-4.6,10.736,1.812,10.736,4.6,6.269,7.606-13.777,7.606-13.777" transform="translate(60.154 -1708.778)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            <path className="sapatilha-svg" id="Path_175" data-name="Path 175" d="M141.42,1739.678s2.569,2.226-4.976,8.587,1.812,10.737,4.6,6.27a115.612,115.612,0,0,0,6.211-12.6" transform="translate(73.842 -1705.144)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
            <path className="to-animate-dashed" id="Path_175" data-name="Path 175" d="M141.42,1739.678s2.569,2.226-4.976,8.587,1.812,10.737,4.6,6.27a115.612,115.612,0,0,0,6.211-12.6" transform="translate(73.842 -1705.144)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            <path className="sapatilha-svg" id="Path_176" data-name="Path 176" d="M141.043,1735.395s2.945,4.342-4.6,10.7,1.812,10.737,4.6,6.269,7.7-10.576,7.7-10.576" transform="translate(87.53 -1694.581)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
            <path className="to-animate-dashed" id="Path_176" data-name="Path 176" d="M141.043,1735.395s2.945,4.342-4.6,10.7,1.812,10.737,4.6,6.269,7.7-10.576,7.7-10.576" transform="translate(87.53 -1694.581)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            <path className="sapatilha-svg" id="Path_177" data-name="Path 177" d="M139.194,1737.057s4.794,3.52-2.751,9.881,1.812,10.737,4.6,6.27a44.649,44.649,0,0,0,4.377-10.993" transform="translate(103.827 -1689.631)" fill="none" stroke="#fff" strokeMiterlimit="10"/>
            <path className="to-animate-dashed" id="Path_177" data-name="Path 177" d="M139.194,1737.057s4.794,3.52-2.751,9.881,1.812,10.737,4.6,6.27a44.649,44.649,0,0,0,4.377-10.993" transform="translate(103.827 -1689.631)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>
          </g>
        </svg>
      </div>
      <div className="tab-content-text">
        <div id="text1" className="svg-text">
          <div className="white-text">
            <p>{footwearText()}</p>
          </div>
          <div className="red-text">
          <a href="./footwear/">
            <div className="link-wrapper">
              <div className="link-text">See our shoes</div>
              <div className="link-arrow"><span className="arrow-icon"></span></div>
            </div>  
          </a>
          </div>
        </div>
       </div> 
    </div>

    <div className={`tab-content ${tabClassifier(2)}`}>
      <div className="svg">
        <svg id="svg-drawing-three" className="svg-drawing third-svg invisible" xmlns="http://www.w3.org/2000/svg" width="849.86" height="495.707" viewBox="0 0 849.86 495.707">
          <g id="Component_51_1" data-name="Component 51 – 1" transform="translate(0.691 1.5)">
            <g id="Group_126" data-name="Group 126" transform="translate(3.57)">
              <path className="home-svg" id="Path_141" data-name="Path 141" d="M-39.138,53.423l-50.2,63.156,18.35,20.742H-3.262l13.283-20.742Z" transform="translate(89.336 107.044)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_141" data-name="Path 141" d="M-39.138,53.423l-50.2,63.156,18.35,20.742H-3.262l13.283-20.742Z" transform="translate(89.336 107.044)" fill="none" stroke="#1f1f1f" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="home-svg" id="Path_142" data-name="Path 142" d="M-68.851,78.871" transform="translate(136.615 165.494)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_142" data-name="Path 142" d="M-68.851,78.871" transform="translate(136.615 165.494)" fill="none" stroke="#1f1f1f" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="home-svg" id="Path_143" data-name="Path 143" d="M-73.613,135.708l-8.1-21.363,24.944-60.911,24.55,60.911-6.244,21.363Z" transform="translate(106.922 108.658)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_143" data-name="Path 143" d="M-73.613,135.708l-8.1-21.363,24.944-60.911,24.55,60.911-6.244,21.363Z" transform="translate(106.922 108.658)" fill="none" stroke="#1f1f1f" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="home-svg" id="Path_144" data-name="Path 144" d="M-89.334,73.175l25.313-.856,24.81,1.79,24.959-1.79,24.383.856" transform="translate(89.34 150.371)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_144" data-name="Path 144" d="M-89.334,73.175l25.313-.856,24.81,1.79,24.959-1.79,24.383.856" transform="translate(89.34 150.371)" fill="none" stroke="#1f1f1f" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="home-svg" id="Path_145" data-name="Path 145" d="M-74.241,246.594-73.681,6.2" transform="translate(124.174 -2.228)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_145" data-name="Path 145" d="M-74.241,246.594-73.681,6.2" transform="translate(124.174 -2.228)" fill="none" stroke="#1f1f1f" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="home-svg" id="Path_146" data-name="Path 146" d="M-77.58,5V9.666h23V5Z" transform="translate(116.469 -5)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_146" data-name="Path 146" d="M-77.58,5V9.666h23V5Z" transform="translate(116.469 -5)" fill="none" stroke="#1f1f1f" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="home-svg" id="Path_147" data-name="Path 147" d="M-75.636,59.459V72.929h9.218V59.459Z" transform="translate(120.705 123.999)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_147" data-name="Path 147" d="M-75.636,59.459V72.929h9.218V59.459Z" transform="translate(120.705 123.999)" fill="none" stroke="#1f1f1f" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            </g>
            <g id="Group_127" data-name="Group 127" transform="translate(744.953)">
              <path className="home-svg" id="Path_141-2" data-name="Path 141" d="M-39.138,53.423l-50.2,63.156,18.35,20.742H-3.262l13.283-20.742Z" transform="translate(89.336 107.044)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_141-2" data-name="Path 141" d="M-39.138,53.423l-50.2,63.156,18.35,20.742H-3.262l13.283-20.742Z" transform="translate(89.336 107.044)" fill="none" stroke="#1f1f1f" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="home-svg" id="Path_142-2" data-name="Path 142" d="M-68.851,78.871" transform="translate(136.615 165.494)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_142-2" data-name="Path 142" d="M-68.851,78.871" transform="translate(136.615 165.494)" fill="none" stroke="#1f1f1f" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="home-svg" id="Path_143-2" data-name="Path 143" d="M-73.613,135.708l-8.1-21.363,24.944-60.911,24.55,60.911-6.244,21.363Z" transform="translate(106.922 108.658)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_143-2" data-name="Path 143" d="M-73.613,135.708l-8.1-21.363,24.944-60.911,24.55,60.911-6.244,21.363Z" transform="translate(106.922 108.658)" fill="none" stroke="#1f1f1f" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="home-svg" id="Path_144-2" data-name="Path 144" d="M-89.334,73.175l25.313-.856,24.81,1.79,24.959-1.79,24.383.856" transform="translate(89.34 150.371)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_144-2" data-name="Path 144" d="M-89.334,73.175l25.313-.856,24.81,1.79,24.959-1.79,24.383.856" transform="translate(89.34 150.371)" fill="none" stroke="#1f1f1f" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="home-svg" id="Path_145-2" data-name="Path 145" d="M-74.241,246.594-73.681,6.2" transform="translate(124.174 -2.228)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_145-2" data-name="Path 145" d="M-74.241,246.594-73.681,6.2" transform="translate(124.174 -2.228)" fill="none" stroke="#1f1f1f" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="home-svg" id="Path_146-2" data-name="Path 146" d="M-77.58,5V9.666h23V5Z" transform="translate(116.469 -5)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_146-2" data-name="Path 146" d="M-77.58,5V9.666h23V5Z" transform="translate(116.469 -5)" fill="none" stroke="#1f1f1f" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="home-svg" id="Path_147-2" data-name="Path 147" d="M-75.636,59.459V72.929h9.218V59.459Z" transform="translate(120.705 123.999)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_147-2" data-name="Path 147" d="M-75.636,59.459V72.929h9.218V59.459Z" transform="translate(120.705 123.999)" fill="none" stroke="#1f1f1f" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            </g>
            <path className="home-svg" id="Path_148" data-name="Path 148" d="M121.618,149.619c1.113-3.366,6.43,0,6.43,0l36.085-2.523H200l5.684-4.114s4.874-2.528,5.844,0,6.916,69.925,6.916,69.925,6.4,3.766,6.4,6.085-6.4,3.193-6.4,3.193l-88.5,2.035s-5.878-1.083-6.354-3.519,4.451-6.224,4.451-6.224L123.6,161.385S120.505,152.986,121.618,149.619Z" transform="translate(87.907 142.378)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
            <path className="to-animate-dashed" id="Path_148" data-name="Path 148" d="M121.618,149.619c1.113-3.366,6.43,0,6.43,0l36.085-2.523H200l5.684-4.114s4.874-2.528,5.844,0,6.916,69.925,6.916,69.925,6.4,3.766,6.4,6.085-6.4,3.193-6.4,3.193l-88.5,2.035s-5.878-1.083-6.354-3.519,4.451-6.224,4.451-6.224L123.6,161.385S120.505,152.986,121.618,149.619Z" transform="translate(87.907 142.378)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            <path className="home-svg" id="Path_149" data-name="Path 149" d="M121.537,147.462c.74-2.43,4.278,0,4.278,0l24.008-1.822h23.865l3.782-2.971s3.243-1.825,3.888,0,4.6,50.485,4.6,50.485,4.259,2.719,4.259,4.393-4.259,2.305-4.259,2.305l-58.878,1.469s-3.911-.782-4.228-2.541,2.961-4.494,2.961-4.494l-2.961-38.332S120.8,149.892,121.537,147.462Z" transform="translate(207.065 165.276)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
            <path className="to-animate-dashed" id="Path_149" data-name="Path 149" d="M121.537,147.462c.74-2.43,4.278,0,4.278,0l24.008-1.822h23.865l3.782-2.971s3.243-1.825,3.888,0,4.6,50.485,4.6,50.485,4.259,2.719,4.259,4.393-4.259,2.305-4.259,2.305l-58.878,1.469s-3.911-.782-4.228-2.541,2.961-4.494,2.961-4.494l-2.961-38.332S120.8,149.892,121.537,147.462Z" transform="translate(207.065 165.276)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            <path className="home-svg" id="Path_150" data-name="Path 150" d="M121.651,151.579c1.263-4.216,7.3,0,7.3,0l40.962-3.16H210.63l6.453-5.153s5.532-3.167,6.634,0,7.851,87.577,7.851,87.577,7.267,4.716,7.267,7.621-7.267,4-7.267,4l-100.457,2.549s-6.673-1.357-7.213-4.407,5.052-7.8,5.052-7.8l-5.052-66.5S120.388,155.795,121.651,151.579Z" transform="translate(381.565 124.891)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
            <path className="to-animate-dashed" id="Path_150" data-name="Path 150" d="M121.651,151.579c1.263-4.216,7.3,0,7.3,0l40.962-3.16H210.63l6.453-5.153s5.532-3.167,6.634,0,7.851,87.577,7.851,87.577,7.267,4.716,7.267,7.621-7.267,4-7.267,4l-100.457,2.549s-6.673-1.357-7.213-4.407,5.052-7.8,5.052-7.8l-5.052-66.5S120.388,155.795,121.651,151.579Z" transform="translate(381.565 124.891)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            <path className="home-svg" id="Path_151" data-name="Path 151" d="M-91.209,114.009H15.4l-11.359,5.87h-83.95Z" transform="translate(91.209 246.719)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
            <path className="to-animate-dashed" id="Path_151" data-name="Path 151" d="M-91.209,114.009H15.4l-11.359,5.87h-83.95Z" transform="translate(91.209 246.719)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            <path className="home-svg" id="Path_164" data-name="Path 164" d="M-91.209,114.009H15.4l-11.359,5.87h-83.95Z" transform="translate(833.078 243.786)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
            <path className="to-animate-dashed" id="Path_164" data-name="Path 164" d="M-91.209,114.009H15.4l-11.359,5.87h-83.95Z" transform="translate(833.078 243.786)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            <path className="home-svg" id="Path_152" data-name="Path 152" d="M-88.191,115.822V243.054" transform="translate(99.359 250.776)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
            <path className="to-animate-dashed" id="Path_152" data-name="Path 152" d="M-88.191,115.822V243.054" transform="translate(99.359 250.776)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            <path className="home-svg" id="Path_163" data-name="Path 163" d="M-88.191,115.822V243.054" transform="translate(841.228 247.843)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
            <path className="to-animate-dashed" id="Path_163" data-name="Path 163" d="M-88.191,115.822V243.054" transform="translate(841.228 247.843)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            <path className="home-svg" id="Path_153" data-name="Path 153" d="M-62.761,115.822V243.432" transform="translate(158.053 250.776)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
            <path className="to-animate-dashed" id="Path_153" data-name="Path 153" d="M-62.761,115.822V243.432" transform="translate(158.053 250.776)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            <path className="home-svg" id="Path_161" data-name="Path 161" d="M-62.761,115.822V243.432" transform="translate(899.922 247.843)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
            <path className="to-animate-dashed" id="Path_161" data-name="Path 161" d="M-62.761,115.822V243.432" transform="translate(899.922 247.843)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            <path className="home-svg" id="Path_154" data-name="Path 154" d="M-88.191,149.605h83.3" transform="translate(99.359 328.747)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
            <path className="to-animate-dashed" id="Path_154" data-name="Path 154" d="M-88.191,149.605h83.3" transform="translate(99.359 328.747)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            <path className="home-svg" id="Path_162" data-name="Path 162" d="M-88.191,149.605h83.3" transform="translate(841.228 325.813)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
            <path className="to-animate-dashed" id="Path_162" data-name="Path 162" d="M-88.191,149.605h83.3" transform="translate(841.228 325.813)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            <path className="home-svg" id="Path_155" data-name="Path 155" d="M46.373,300.453V321.07l-8.545,8.612v39.273H63.074V329.682l-6.645-8.612V300.453Z" transform="translate(1.514 -11.16)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
            <path className="to-animate-dashed" id="Path_155" data-name="Path 155" d="M46.373,300.453V321.07l-8.545,8.612v39.273H63.074V329.682l-6.645-8.612V300.453Z" transform="translate(1.514 -11.16)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            <g id="Group_128" data-name="Group 128" transform="translate(144.175 254.108)">
              <path className="home-svg" id="Path_156" data-name="Path 156" d="M119.287,248.023h67.688V384.228h-56.5Z" transform="translate(-119.287 -159.249)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_156" data-name="Path 156" d="M119.287,248.023h67.688V384.228h-56.5Z" transform="translate(-119.287 -159.249)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="home-svg" id="Path_157" data-name="Path 157" d="M267.687,248.023h66.98L322.972,384.228H267.687Z" transform="translate(223.216 -159.249)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_157" data-name="Path 157" d="M267.687,248.023h66.98L322.972,384.228H267.687Z" transform="translate(223.216 -159.249)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="home-svg" id="Path_158" data-name="Path 158" d="M157.293,438.655H580.247V340.068H157.293V308.393H132.147l4.248-78.38s113.986-14.417,230.111,0c12.339,1.532,31.634-3.038,42.4-3.983,9.776-.854,45.353-3.189,55.515-4.122,12.764-1.175,1.607-.722,30.922,0,60.638-.833,105.765,8.105,105.765,8.105l8.154,78.38H580.247" transform="translate(-89.606 -221.186)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_158" data-name="Path 158" d="M157.293,438.655H580.247V340.068H157.293V308.393H132.147l4.248-78.38s113.986-14.417,230.111,0c12.339,1.532,31.634-3.038,42.4-3.983,9.776-.854,45.353-3.189,55.515-4.122,12.764-1.175,1.607-.722,30.922,0,60.638-.833,105.765,8.105,105.765,8.105l8.154,78.38H580.247" transform="translate(-89.606 -221.186)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="home-svg" id="Path_159" data-name="Path 159" d="M139.749,267.408H562.2" transform="translate(-72.061 -114.507)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_159" data-name="Path 159" d="M139.749,267.408H562.2" transform="translate(-72.061 -114.507)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

              <path className="home-svg" id="Path_160" data-name="Path 160" d="M203.6,224V433.343" transform="translate(81.928 -215.874)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
              <path className="to-animate-dashed" id="Path_160" data-name="Path 160" d="M203.6,224V433.343" transform="translate(81.928 -215.874)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            </g>
            <path className="home-svg" id="Path_165" data-name="Path 165" d="M553.853,302.554h44.828V355.27H553.853Z" transform="translate(211.682 -4.463)" fill="none" stroke="#fff" strokeWidth="3" strokeMiterlimit="10"/>
            <path className="to-animate-dashed" id="Path_165" data-name="Path 165" d="M553.853,302.554h44.828V355.27H553.853Z" transform="translate(211.682 -4.463)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

            <path className="home-svg" id="Path_166" data-name="Path 166" d="M153.4,103.08l17.031,25.885" transform="translate(656.958 221.368)" fill="none" stroke="#fff" strokeWidth="3" stoke-miterlimit="10"/>
            <path className="to-animate-dashed" id="Path_166" data-name="Path 166" d="M153.4,103.08l17.031,25.885" transform="translate(656.958 221.368)" fill="none" stroke="#1F1F1F" strokeWidth="3" strokeDasharray="0.5 0.5"/>

          </g>
        </svg>  
      </div>
      <div className="tab-content-text">
        <div id="text1" className="svg-text">
          <div className="white-text">
            <p>{homewareText()}</p>
          </div>
          <div className="red-text">
            <a href="./homeware/">
                <div className="link-wrapper">
                  <div className="link-text">See our homeware</div>
                  <div className="link-arrow"><span className="arrow-icon"></span></div>
                </div>  
              </a>
          </div>
        </div>
       </div> 
    </div>
  </div>


  </div>;
};

export default SVGStatsView;
