import React from 'react';
import './backgroundHeadingAndLede.less';

const BackgroundHeadingAndLedeView = (props) => {
  // console.log("*************************");
  // console.log(props);
  const linerBreaker = (text) => {
    
    // return (text!=null && text!=undefined) ? text.replace(/\|/g, '<br />') : text;
    const result = (text!=null && text!=undefined) ? text.replace(/\|/g, '<br />') : text;
    // console.log("result", result);
    return {__html: result};
  }

  let blockClass = "backgroundHeadingAndLedeBlock " + props.data.theme + " " + props.data.layout + " " + props.data.align;
  return <div className={blockClass} >
    <div className="headingAndLedeText">
      <h2 className="heading">{props.data.heading || ''}</h2>
      {props.data.subheading ? <h3 className="subheading" dangerouslySetInnerHTML={linerBreaker(props.data.subheading)}></h3> : ''}
      {/* <h3 class="subheading">{props.data.subheading || ''}</h3> */}
      <div className="lede" dangerouslySetInnerHTML={linerBreaker(props.data.lede)}></div>
    </div>
  </div>;
};

export default BackgroundHeadingAndLedeView;
