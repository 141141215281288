import React, { useRef } from 'react';
import './backToTop.less';

const discreetOffset = 600;

class BackToTopView extends React.Component {

  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      shouldShow: false,
      discreet: false,
      class: "invisible"
    };    
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount = () => {
      window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    try {
      const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
      const pageSize = window.innerHeight;
      const pageDepth = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
      );
      const backToTopThreashold = pageSize/5;
      // const scrollTop = this.scrollRef.current.scrollTop
      // console.log(`onScroll, window.scrollY: ${scrollY} scrollRef.scrollTop: ${scrollTop}`)
      // console.log(`onScroll, window.scrollY: ${scrollY} of ${pageDepth} with pages of ${pageSize}`);
      if (scrollY > backToTopThreashold) {
        if (!this.state.shouldShow) {
          this.state.shouldShow = true;
          this.state.class = "visible";
          this.setState({scrollHelper : this.state.class});
        } else if ((!this.state.discreet) && (scrollY > backToTopThreashold+discreetOffset)) {
          this.state.discreet = true;
          this.state.class = "visible discreet";
          this.setState({scrollHelper : this.state.class});
        }
        
      } else if ((scrollY <= backToTopThreashold) && (this.state.shouldShow)) {
        this.state.shouldShow = false;
        this.state.discreet = false;
        this.state.class = "invisible";
        this.setState({scrollHelper : this.state.class});
      }
    } catch (e) {
      console.log("EXCEPTION" + e);
    }
    // let element = e.target
    // if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    //   // do something at end of scroll
    // }
    // console.log("++++++++++++++++ WE ARE SCROLLING");
    
  }

  removeDiscreet() {
    this.state.class = "visible";
    this.state.discreet = false;
    this.setState({scrollHelper : this.state.class});
  }

  setDiscreet() {
    this.state.discreet = true;
    this.state.class = "visible discreet";
    this.setState({scrollHelper : this.state.class});
  }

  render() {
    return (
      <div id="backToTop" className={this.state.class} 
            onMouseEnter={() => this.removeDiscreet()}
            onMouseLeave={() => this.setDiscreet()}>
        <a href="#main" onScroll={this.handleScroll}><span className="hint">Back to Top</span></a>
      </div>
    );
  }
}

export default BackToTopView;