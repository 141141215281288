import React, { useState } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';


import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import AlignBlock from '@plone/volto/components/manage/Sidebar/AlignBlock';

import TestimonialCardView from './TestimonialCardView';
import TestimonialCardSchema from './TestimonialCardSchema';

import imageSVG from '@plone/volto/icons/image.svg';


import {
  Icon,
  SidebarPortal,
} from '@plone/volto/components';

export const HeroBannerMessages = defineMessages({
  showAll: {
    id: 'Show All',
    defaultMessage: 'Show All',
  },
});

const TestimonialCardEdit = (props) => {
  const { selected, onChangeBlock, block, data, intl, openObjectBrowser, required, resetSubmitUrl } = props;
  // const [activeFilter, setActiveFilter] = useState(0);

  return (
    <div>
      <SidebarPortal selected={selected}>

      <InlineForm
          icon={<Icon size="24px" name={imageSVG} />}
          schema={TestimonialCardSchema}
          title={TestimonialCardSchema.title}
          headerActions={<button onClick={() => {}}>Action</button>}
          footer={<div></div>}
          onChangeField={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          formData={data}
        />


       </SidebarPortal>

       <TestimonialCardView {...props} />

    </div>
  );
};

export default TestimonialCardEdit;
