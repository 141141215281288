/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@plone/volto/reducers';
import imageGallery from './content_types/gallery_images';
import pages from './content_types/pages';
import related_pages from './content_types/get_related_pages';
import footer_links from './content_types/get_footer_links';
import contactForm from './content_types/contact_form';

/**
* Root reducer.
* @function
* @param {Object} state Current state.
* @param {Object} action Action to be handled.
* @returns {Object} New state.
*/
const reducers = {
    ...defaultReducers,
    imageGallery,
    pages,
    related_pages,
    footer_links,
    contactForm
};

export default reducers;
