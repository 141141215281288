/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */


// All your imports required for the config here BEFORE this line
import config from '@plone/volto/registry'

// import '@plone/volto/config';
import {
  customBlocks as customBlocks,
} from '@glisco/custom-blocks';

import { GoogleAnalyticsInsertion, ScrollEffectsInsertion } from './components';


const unwatedDefaultBlocks = ["toc", "hero", "maps", "table", "leadimage" ];
const googleAnalyticsMeasurementID = process.env.RAZZLE_GA4_CODE || "G-RDFDDCQ6LB";

// console.log("***************** config.blocks process ENV *****************");
// console.log(process.env);

const filterConfig = (k,v) => {
  // console.log("Checking K:" + k + ", V:" + v);
  return !unwatedDefaultBlocks.includes(v.id);
}
// const gtDefaultBlocks = defaultBlocks.filter( e => !unwatedDefaultBlocks.entries.includes(e.id));
const gtDefaultBlocks =  (blocksConfig) => Object.fromEntries(Object.entries(blocksConfig).filter(([k,v]) => filterConfig(k,v)));
export default function applyConfig(config) {

  // SAMPLE CONFIGURATION 
  // https://github.com/eea/volto-freshwater/blob/master/src/index.js
  
  // config.views.layoutViews = {
  //   ...config.views.layoutViews,
  //   default_page_view: DefaultPageEditView,
  // };

  config.blocks.blocksConfig = {
    ...gtDefaultBlocks(config.blocks.blocksConfig), 
    ...customBlocks
  }  

  config.settings.appExtras = [
    ...config.settings.appExtras,
    {
      match: '',
      component: GoogleAnalyticsInsertion,
      props: {
        'googleMeasurementId': googleAnalyticsMeasurementID
      }
    },
    {
      match: '',
      component: ScrollEffectsInsertion,
      props: {
        'someProp': "someValue"
      }
    },
  ]

  // console.log("***************** config.settings.appExtras *****************")
  // console.log(JSON.stringify(config.settings.appExtras, null, 2));
  // console.log(JSON.stringify(config.settings), null, 2);

  return config;
}
