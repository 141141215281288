const ISVGStatsSchema = {
    title: 'SVG Stats',

    fieldsets: [
      {
        id: 'default',
        title: 'SVGStats',
        fields: [
          'textileText',
          'footwearText',
          'homewareText',
        ],
      },
    ],

    properties: {
      textileText: {
        title: 'Textile',
        description: 'Text for textiles',
        widget: 'text',
      },

      footwearText: {
        title: 'Footwear',
        description: 'Text for Footwear',
        widget: 'text',
      },

      homewareText: {
        title: 'Homeware',
        description: 'Text for Homeware',
        widget: 'text',
      },

    },

    required: [],
  };

  export default ISVGStatsSchema;
