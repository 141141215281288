
const ITestimonialCardSchema = {
    title: 'Testemonial Card',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
        'image',
          'name',
          'jobTitle',
          'company',
          'testimonialText',
          'placeTextLeft',
          'textBackground',
          'useLightVersion',
          'blackAndWhite'
        ],
      },
    ],

    properties: {

      image: {
        title: 'Image',
        description: 'Choose Background Image',
        widget: 'object_browser',
        selectableTypes : ['Image'],
        mode: 'link',
      },

      name: {
        title: 'Name',
        description: 'Insert Testimonial Name',
        widget: 'text',
      },

      jobTitle: {
        title: 'Job Title',
        description: 'Insert Testimonial Job Title',
        widget: 'text',
      },

      company: {
        title: 'Company',
        description: 'Insert Testimonial Company',
        widget: 'text',
      },

      testimonialText: {
        title: 'Testimonial Text',
        description: 'Insert Testemonial Text',
        widget: 'text',
      },

      placeTextLeft: {
        title: 'Place Text Left',
        description: 'Inverts order of text & image',
        type: 'boolean',
      },

      textBackground: {
        title: 'Text Theme',
        description: '',
        type: 'choice',
        choices: [['lighterBackground', 'Lighter Background'], ['darkerBackground', 'Darker Background']]
      },

      useLightVersion: {
        title: 'Use Slim Layout',
        description: 'Use this for multiple testimonial rows',
        type: 'boolean',
        default: false
      },

      blackAndWhite: {
        title: 'Black & White',
        description: 'Make Picture B&W',
        type: 'boolean',
      },


      

    },

    required: [],

  };

  export default ITestimonialCardSchema;
