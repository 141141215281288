const IPictureGallerySchema = {
    title: 'Picture Gallery',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'selector',
          'photoCount',
          'blackAndWhite'
        ],
      },
    ],

    properties: {

      selector: {
        title: 'Selector Tag',
        description: 'The tag used to select content, e.g. "home-picture-gallery" or "fabrics"',
        widget: 'text'
      },

      photoCount: {
        title: '# Photos',
        description: 'Maximum Number of Photos',
        widget: 'text'
      }, 

      blackAndWhite: {
        title: 'Black & White',
        description: 'Make Picture B&W',
        type: 'boolean',
      },

    },

    required: ['selector'],
  };

  export default IPictureGallerySchema;
