const IHeroBannerSchema = {
    title: 'Hero Banner',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'heading',
          'subheading',
          'highlight',
          'lede',
          'image',
          'fixToTop',
          'height',
          'showCursor',
          'textBackground',
          'blackAndWhite'
        ],
      },
    ],

    properties: {
      heading: {
        title: 'Heading',
        description: 'Insert Heading Text',
        widget: 'text',
      },

      subheading: {
        title: 'Sub Heading',
        description: 'Insert Sub Heading Text',
        widget: 'text',
      },

      highlight: {
        title: 'Highlight',
        description: 'Insert Highlighted Text',
        widget: 'text',
      },

      lede: {
        title: 'Lede text',
        description: 'Insert Lede Text',
        widget: 'text',
      },

      image: {
        title: 'Image',
        description: 'Choose Background Image',
        widget: 'object_browser',
        selectableTypes : ['Image'],
        mode: 'link'
      },

      fixToTop: {
        title: 'Fix to Top of Page',
        description: 'Acts as Page Header',
        type: 'boolean',
        // widget: 'checkbox',
      },

      height: {
        title: 'Half Height',
        description: 'Reduce hero to 75% or 50% height',
        type: 'choice',
        choices: [['halfHeight', 'Half Height'], ['twoThirdsHeight', 'Two Thirds Height'], ['fullHeight', 'Full Height']]

      },

      textBackground: {
        title: 'Darker Background on Text',
        description: '',
        type: 'choice',
        choices: [['lighterBackground', 'Lighter Background'], ['darkerBackground', 'Darker Background']]
      },

      showCursor: {
        title: 'Show Cursor',
        description: 'Shows Overlay of Cursor Image',
        type: 'boolean',
        // widget: 'checkbox',
      },

      textBackground: {
        title: 'Darker Background on Text',
        description: '',
        type: 'choice',
        choices: [['lighterBackground', 'Lighter Background'], ['darkerBackground', 'Darker Background']]
      },

      blackAndWhite: {
        title: 'Black & White',
        description: 'Make video B&W',
        type: 'boolean',
      },


    },

    required: ['heading'],
  };

  export default IHeroBannerSchema;
