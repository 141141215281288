/**
 * Gallery Images reducer.
 * @module reducers/content_types/pages
 */

 import { map } from 'lodash';
 import config from '@plone/volto/registry';

 import { GET_RELATED_PAGES } from '../../constants/ActionTypes';

 const UNDEFINED = "notag";

 const initialState = {
   error: null,
   loaded: false,
   loading: true,
   pages: {
    UNDEFINED:[],
   }
 };


 /**
  * Faq reducer.
  * @function pages
  * @param {Object} state Current state.
  * @param {Object} action Action to be handled.
  * @returns {Object} New state.
  */
 export default function related_pages(state = initialState, action = {}) {
   let currentRelatedPages = state.related_pages || {}; 

   switch (action.type) {
     case `${GET_RELATED_PAGES}_PENDING`:
       return {
         ...state,
         error: "GET_RELATED_PAGES",
         loading: true,
         loaded: false,
         pages: {
           ...state.pages
         },
       };
     case `${GET_RELATED_PAGES}_SUCCESS`:
        // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>> related_pages REDUCER for " + action.filter);
        // console.log("Type of action.filter " + action.filter + " is " + typeof(action.filter));
        // console.log(action);
        // console.log(state);
    
       const results = {};
       results[String(action.filter)] = map(action.result.items, item => ({
            ...item,
            id: item['@id'].replace(config.settings.apiPath, ''),
        }));

      const mergedResults = {
         ...state,
          error: "DATA RECEIVED SUCCESSFULLY",
          loaded: true,
          loading: false,
          pages: {
            ...state.pages,
            ...results
          }
       };
      //  console.log(mergedResults);
      //  console.log("END >>>>>>>>>>>>>>>>>>>>>>>>>>>>>> related_pages REDUCER for " + action.filter);
       return mergedResults;

     case `${GET_RELATED_PAGES}_FAIL`:
       return {
         ...state,
         error: action.error,
         loading: false,
         loaded: false,
         pages: {
          ...state.pages
         },
       };
     default:
       return state;
   }
 }
