import { exact } from 'prop-types';
import React, { useEffect, useRef, useState }  from 'react';
import './testimonial-card.less';



const TestimonialCardView = (props) => {
  let ref = useRef();
  const [config, setConfig] = useState();

  const textBackground = props.data.textBackground;
  const placeTextLeft = props.data.placeTextLeft ? "placeTextLeft" : "";
  const light = props.data.useLightVersion ? "light" : "";
  const withImage = () => {
    try {
      return (props.data.image.length == 0) ? "textOnly" : "" ;
    } catch (e) {
      return "textOnly";
    }
    
  }
  const blockClass = `testimonialCard section ${placeTextLeft} ${textBackground} ${light} ${withImage()}` ;

  const imgUrl = () => {
    // console.log(JSON.stringify(props.data.image));

    let imagePath = "";
    try {
      imagePath = props.data.image[0]["getURL"].replace(process.env.RAZZLE_API_PATH, "") + '/@@images/image.png';
    } catch (e) {
      console.log("Error in IMG URL in TestimonialCardView: " + e.toString());
    }

    return imagePath;
  }

  const getHeight = () => {
    try {
      const container = ref.current.closest(".columns-view");
      const testimonals = container.querySelectorAll(".testimonialCard");

      const heights = Array.from(testimonals).map((testimonial) => testimonial.clientHeight);
      const maxHeight = Math.max(...heights);
      return {
        height: maxHeight + "px"
      }
    } catch (e) {
      // console.log("Error in getHeight in TestimonialCardView: " + e.toString());
    }    
  }  

  const getName = () => {
    // console.log(JSON.stringify(props.data.image));

    let name = "";

    try {
      name = props.data.name;
    } catch (e) {
      console.log("Error in Name in TestimonialCardView: " + e.toString());
    }

    return name;
  }

  const getJobTitle = () => {
    // console.log(JSON.stringify(props.data.image));

    let jobTitle = "";
    try {
      jobTitle = props.data.jobTitle;
    } catch (e) {
      console.log("Error in Job Title in TestimonialCardView: " + e.toString());
    }

    return jobTitle;
  }

  const getCompany = () => {
    // console.log(JSON.stringify(props.data.image));

    let company = "";
    try {
      company = props.data.company;
    } catch (e) {
      console.log("Error in Company in TestimonialCardView: " + e.toString());
    }

    return company;
  }

  const getTestimonialText = () => {
    // console.log(JSON.stringify(props.data.image));

    let testimonialText = "";
    try {
      testimonialText = props.data.testimonialText;
    } catch (e) {
      console.log("Error in Testimonial Text in TestimonialCardView: " + e.toString());
    }

    return testimonialText;
  }

  const getColorSetting = () => {
    try {
      return props.data.blackAndWhite ? "blackAndWhite testimonial-card-image" : "color testimonial-card-image";
    } catch(e) {
      return "color";
    }
  }



  return <div ref={ref} className={blockClass} style={getHeight()}>

          <div className={getColorSetting()}>
            <img src={imgUrl()} alt="testimonial-image">
            </img>
          </div>

          <div className="testimonial-card-content" style={getHeight()}>

            <div className="testimonial-card-content-text">
              <div className="testimonial-card-content-text-testimonial">
                {getTestimonialText()}
              </div>
              <div className="testimonial-information">
                <span className="name">{getName()}</span>
                <span className="jobTitle">{getJobTitle()}</span>
                <span className="company">{getCompany()}</span>                
              </div>
            </div>

          </div>

         </div>;
};

export default TestimonialCardView;
