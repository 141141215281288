/** Image Gallery actions.
* @module actions/content_types/gallery_images
*/

import { GET_GALLERY_IMAGES } from '../../constants/ActionTypes';

/**
* Get FAQ items.
* @function getGalleryImages
* @returns {Object} Images action.
*/
export function getGalleryImages(tag) {
 return {
   type: GET_GALLERY_IMAGES,
   request: {
     op: 'get',
     path: `/@search?portal_type=Image&Subject=${tag}&fullobjects=1`,
   },
 };
}

// export function galleryImagesResult() {
//   return {
//     type : GET_GALLERY_IMAGES_SUCCESS,
//     result : {
//       id : '',
//       items: [],
//       items_total: 0
//     }
//   }
// }
// GET_GALLERY_IMAGES_SUCCESS returns the following
// {
//   result: {
//     '@id': 'http://localhost:3000/api/@search?portal_type=Image&Subject=joe',
//     items: [ [Object], [Object] ],
//     items_total: 2
//   },
//   type: 'GET_GALLERY_IMAGES_SUCCESS'
// }
