/**
 * Gallery Images reducer.
 * @module reducers/content_types/gallery_images
 */

 import { map } from 'lodash';

 import { CONTACT_FORM_SUBMIT } from '../../constants/ActionTypes';

 const initialState = {
   error: null,
   loaded: false,
   loading: true,
   images: []
 };

 /**
  * Faq reducer.
  * @function contactForm
  * @param {Object} state Current state.
  * @param {Object} action Action to be handled.
  * @returns {Object} New state.
  */
 export default function contactForm(state = initialState, action = {}) {
   switch (action.type) {
     case `${CONTACT_FORM_SUBMIT}_PENDING`:
        console.log(`${CONTACT_FORM_SUBMIT}_PENDING`);
        // console.log(action);

       return {
         ...state,
         error: "Submitting Contact Form",
         loading: true,
         loaded: false,
       };
     case `${CONTACT_FORM_SUBMIT}_SUCCESS`:
       console.log(`${CONTACT_FORM_SUBMIT}_SUCCESS`);
       const results = map(action.result.items, item => ({
            ...item
        }));
       return {
         ...state,
          error: "FORM SUBMITTED SUCCESSFULLY",
          loaded: true,
          loading: false,
       };

     case `${CONTACT_FORM_SUBMIT}_FAIL`:
        console.log(`${CONTACT_FORM_SUBMIT}_FAIL`);
       return {
         ...state,
         error: action.error,
         loading: false,
         loaded: false,
       };
     default:
       return state;
   }
 }
