import React, { useEffect, useRef, useState, Component }  from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {getRelatedPages} from '../../../../actions';
import { getBaseUrl } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

import cx from 'classnames';
import './related-content-cards.less';

const MAX_LINKS = 4;

class RelatedContentCards extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */

  static propTypes = {
    // getGalleryImages: PropTypes.func.isRequired,
    error: PropTypes.string,
    // gallery: PropTypes.arrayOf(
    //   PropTypes.shape({
    //     '@id': PropTypes.string,
    //     '@type': PropTypes.string,
    //     description: PropTypes.string,
    //     review_state: PropTypes.string,
    //     title: PropTypes.string,
    //   })
    // ),
    lodaded: PropTypes.bool,
    loading: PropTypes.bool,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Navigation
   */
   constructor(props) {
    super(props);

    const hideMetadata = props.data.hideMetadata ? "hideMetadata" : "";
    const editClassifier = this.isEdit() ? "editMode" : ""

    this.blockClass = "relatedContentCardsBlock" + " " + hideMetadata + editClassifier;

    const selector = this.props.selector != null && this.props.selector.trim().length > 0 ? this.props.selector : "gallery";

    // console.log("GETTING PAGES FROM PLONE FOR SELECTOR (" + selector +")");

    this.props.getPagesFromPlone(selector);

  }

  isEdit = () => {
    return this.props.data.edit ? true : false;
  }

  generateKey = (seed) => {
    let d = new Date();
    let time = d.getTime();
    let key = seed + "." + Math.floor(Math.random() * 10000) + "." + time;
    return key.toString('base64');
  }

  // pageCount = () => Math.ceil(Math.max(1, this.visibleItems().length / (this.props.elementsPerRow * this.props.rows)));

  // visibleItems = () => this.props.pictureData.filter((item) => (this.cleanFilter(this.state.filter)=="show-all" || this.imageHasTag(item, this.cleanFilter(this.state.filter))) );

  // pages = () => this.props.pages == undefined ? [] : this.props.pages;
  pages() {

    // console.log("*********************    ****************************");
    // console.log(this.props.pages);

    let mypages = this.props.pages.map(page => {
          let rObj = {}
          rObj["title"] = page.title;
          rObj["description"] = page.description;
          rObj["subjects"] = page.subjects;
          rObj["meta"] = page.subjects.join(" ") + " | " + page.modified.substring(0,10);
          rObj["img"] = (page.preview_image!=null) ? page.preview_image.download : "/img/image1.jpeg";
          rObj["link"] = page.id;
          rObj["key"] = this.generateKey(page.id);
          return rObj;
    });

    // console.log(">>>>>>>>>>>>>>> mypages");
    // console.log(mypages);

    mypages = mypages.filter(x => x !== undefined)
                     .filter(x => x.subjects.includes(this.props.selector))
                     .filter(item => item.link != this.currentLocation())
                     .slice(0, MAX_LINKS);

    return mypages;

    // return this.props.pages == undefined ? [] : this.props.pages;

    // return [{
    //   title: "Silk Tradition and Innovation working together.",
    //   description: "Just when we thought that we are going overboard with the trend from nineties and noughties, these style mavens make us believe.",
    //   meta: "Resources | 12.02.2021",
    //   img: "img1",
    // },
    // {
    //   title: "Lorem ipsum dolor sit amet, consectetur.",
    //   desciption: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia in deserunt mollit anim id est sint laborum.",
    //   meta: "Fashion | 21.01.2021",
    //   img: "img2",
    // },
    // {
    //   title: "3D Virtual Sampling. The latest technology.",
    //   description: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia in deserunt mollit anim id est sint",
    //   meta: "Technology | 08.12.2020",
    //   img: "img3",
    // }]
  }

  currentLocation = () => {
      return (window != undefined) ? window.location.pathname : undefined;
  }
  
  getColorSetting = () => {
    try {
      return this.props.data.blackAndWhite ? "blackAndWhite related-content-card-image" : "color related-content-card-image";
    } catch(e) {
      return "color";
    }
  }


  gallery = () => this.pages().map((item) =>
                    <div className="relatedContentCard" key={item.key}>
                    <a {...(!this.isEdit() ? {href:item.link} : {className:"disabled"})} >
                      <div className={this.getColorSetting()}>
                        <img src={item.img}></img>
                      </div>

                      <div className="related-content-card-meta">
                        {item.meta}
                      </div>

                      <div className="related-content-card-title">
                        {item.title}
                      </div>

                      <div className="related-content-card-description">
                        {item.description}
                      </div>
                      </a>
                    </div>
                  );

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
   render() {
    const { settings } = config;
    // var items = this.pages();


    return (
      <div className={this.blockClass}>
        {this.gallery()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {

  const selector = ownProps.data.selector

  const { error, loaded, loading, pages } = state.related_pages || {error: "", loaded: false, loading: false, pages: {}};

  const propsStruct = {
    ...ownProps.data,
    pages: pages[selector] || [],
    loaded: loaded,
    loading: loading,
    error: error,
    elementsPerRow: 3,
  }

  // console.log("................................ mapStateToProps for " + selector);
  // console.log(ownProps);
  // console.log(pages);
  // console.log(pages[selector]);

  return propsStruct;
}

const mapDispatchToProps = {
  // ... normally is an object full of action creators
  getPagesFromPlone: getRelatedPages
}

// export default RelatedContentCards;

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RelatedContentCards);

// 3. Use setState to store activeFilter (props are RO)
