/** Image Gallery actions.
* @module actions/content_types/get_pages
*/

import { GET_FOOTER_LINKS } from '../../constants/ActionTypes';

/**
* Get FAQ items.
* @function getFooterLinks
* @returns {Object} Images action.
*/
export function getFooterLinks(tag) {
  return {
    type: GET_FOOTER_LINKS,
    request: {
      op: 'get',
      path:'/@navigation?expand.navigation.depth=2'
    }
  };
//   return (tag.length > 0) ? {
//     type: GET_FOOTER_LINKS,
//     request: {
//       op: 'get',
//       path: `/@search?portal_type=Document&Subject=${tag}&fullobjects=1`,
//     },
//  } : {
//     type: GET_FOOTER_LINKS,
//     request: {
//       op: 'get',
//       path: `/@search?portal_type=Document&fullobjects=1`,
//     },
//  };
}
