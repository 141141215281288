import React, { useState } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';


import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import AlignBlock from '@plone/volto/components/manage/Sidebar/AlignBlock';
import imageSVG from '@plone/volto/icons/image.svg';

import SVGStatsView from './SVGStatsView';
import SVGStatsSchema from './SVGStatsSchema';


import {
  Icon,
  SidebarPortal,
} from '@plone/volto/components';


const SVGStatsEdit = (props) => {
  const { selected, onChangeBlock, block, data, intl, openObjectBrowser, required, resetSubmitUrl } = props;
  const [activeFilter, setActiveFilter] = useState(0);

  return (
    <div>
      <SidebarPortal selected={selected}>
        <InlineForm
            icon={<Icon size="24px" name={imageSVG} />}
            schema={SVGStatsSchema}
            title={SVGStatsSchema.title}
            headerActions={<button onClick={() => {}}>Action</button>}
            footer={<div></div>}
            onChangeField={(id, value) => {
              onChangeBlock(block, {
                ...data,
                [id]: value,
              });
            }}
            formData={data}
          />
          
       </SidebarPortal> 

       {/* <SVGStatsView {...props} /> */}
       <div className="svgStatsPlaceholder">
         <h2>Your SVG Stats will appear here</h2>
         <p className="notice">This is not a configurable component. There is nothing available to edit. You can only include or exclude SVG Stats from the page</p>
       </div>
    </div>
  );
};

export default SVGStatsEdit;
