import React, { useEffect, useRef, useState }  from 'react';
import './full-width-divider.less';



const FullWidthDividerView = (props) => {
  let ref = useRef();
  const [config, setConfig] = useState();

  // const stitch = props.data.stitch;

  const showCursor = props.data.showCursor ? "showCursor" : "";
  const twoThirdsHeight = props.data.twoThirdsHeight ? "twoThirdsHeight" : "";
  const textBackground = props.data.textBackground;
  const blackText = props.data.blackText ? "blackText" : "";
  const blockClass = "fullWidthDividerBlock section " + showCursor + " " + twoThirdsHeight + " " + textBackground + " " + blackText;

  const imgUrl = () => {
    // console.log(JSON.stringify(props.data.image));

    let imagePath = "";
    try {
      // console.table(props.data);
      imagePath = props.data.image[0]["getURL"].replace(process.env.RAZZLE_API_PATH, "") + '/@@images/image.png';
    } catch (e) {
      console.log("Error in IMG URL in FullWidthDividerView: " + e.toString());
    }

    return 'url("' + imagePath + '")';
  }

  const getColorSetting = () => {
    try {
      return props.data.blackAndWhite ? "blackAndWhite bgimage" : "color bgimage";
    } catch(e) {
      return "color";
    }
  }


  return <div className={blockClass}  >

    {/* <div className="textContainer">
      <h1>{props.data.heading}</h1>
      <h2 className="sub-heading">{props.data.subheading}</h2>
      <h2 className="highlight">{props.data.highlight}</h2>
      <div className="lede">{props.data.highlight}</div>
    </div> */}

    <div className="textBackgroundOverlay">
    </div>

      <div className="divider-text" >
        <div>
          <div className="noshadow padded-left">
            <h2>{props.data.heading}</h2>
            <h3 className="sub-heading">{props.data.subheading}</h3>
            <h3 className="highlight">{props.data.highlight}</h3>
            <div className="lede">{props.data.lede}</div>
            {props.data.link != null &&
              <div className="learn-more">
                <a href={props.data.link}>Learn more</a>
              </div>
            }
          </div>
        </div>
      </div>
      <div className="divider-background" data-img={imgUrl()}>
        <div className={getColorSetting()} style={{
          backgroundImage : imgUrl()
        }}/>
      </div>
      <div className="scroll-hint">
       <div className="mouse-wheel"></div>
      </div>

  </div>;
};

export default FullWidthDividerView;
