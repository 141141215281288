import tableSVG from '@plone/volto/icons/table.svg';
import chronoSVG from '@plone/volto/icons/chrono.svg';
import appsSVG from '@plone/volto/icons/apps.svg';
import heroSVG from '@plone/volto/icons/hero.svg';
import dividerSVG from '@plone/volto/icons/image-full.svg';
import stichSVG from '@plone/volto/icons/italic.svg';
import headAndLeadSVG from '@plone/volto/icons/border-style.svg';
import seeAlsoSVG from '@plone/volto/icons/show.svg';
import contentLinkSVG from '@plone/volto/icons/link.svg';
import userSVG from '@plone/volto/icons/user.svg';
import playSVG from '@plone/volto/icons/play.svg';
import homeSVG from '@plone/volto/icons/home.svg';
import contactSVG from '@plone/volto/icons/headset-microphone.svg';

import { ObjectBrowserWidgetMode } from '@plone/volto/components/manage/Widgets/ObjectBrowserWidget';

import MetricsView from './Metrics/MetricsView';
import MetricsEdit from './Metrics/MetricsEdit';

import HeroBannerView from './HeroBanner/HeroBannerView';
import HeroBannerEdit from './HeroBanner/HeroBannerEdit';

import FullWidthDividerView from './FullWidthDivider/FullWidthDividerView';
import FullWidthDividerEdit from './FullWidthDivider/FullWidthDividerEdit';

import IconTextView from './IconText/IconTextView';
import IconTextEdit from './IconText/IconTextEdit';

import StitchDividerView from './StitchDivider/StitchDividerView';
import StitchDividerEdit from './StitchDivider/StitchDividerEdit';

import FilterableGalleryView from './FilterableGallery/FilterableGalleryView';
import FilterableGalleryEdit from './FilterableGallery/FilterableGalleryEdit';

import RelatedContentCardsView from './RelatedContentCards/RelatedContentCardsView';
import RelatedContentCardsEdit from './RelatedContentCards/RelatedContentCardsEdit';

import ContactFormView from './ContactForm/ContactFormView';
import ContactFormEdit from './ContactForm/ContactFormEdit';

import BackgroundHeadingAndLedeView from './BackgroundHeadingAndLede/BackgroundHeadingAndLedeView';
import BackgroundHeadingAndLedeEdit from './BackgroundHeadingAndLede/BackgroundHeadingAndLedeEdit';

import SVGStatsView from './SVGStats/SVGStatsView';
import SVGStatsEdit from './SVGStats/SVGStatsEdit';

import ExternalLinkCardView from './ExternalLinkCard/ExternalLinkCardView';
import ExternalLinkCardEdit from './ExternalLinkCard/ExternalLinkCardEdit';

import TestimonialCardView from './TestimonialCard/TestimonialCardView';
import TestimonialCardEdit from './TestimonialCard/TestimonialCardEdit';

import PictureGalleryView from './PictureGallery/PictureGalleryView';
import PictureGalleryEdit from './PictureGallery/PictureGalleryEdit';

import HomePageView from './HomePage/HomePageView';
import HomePageEdit from './HomePage/HomePageEdit';

import ContentMirrorFooterEdit from './ContentMirrorFooter/ContentMirrorFooterEdit';
import ContentMirrorFooterView from './ContentMirrorFooter/ContentMirrorFooterView';

import BackToTopView from "./BackToTop/BackToTopView";

export { MetricsView, MetricsEdit,
         FilterableGalleryView, FilterableGalleryEdit,
         BackgroundHeadingAndLedeView, BackgroundHeadingAndLedeEdit,
         ContentMirrorFooterEdit, ContentMirrorFooterView, BackToTopView };

export const customBlocks = {
    // Hero Banner
    "heroBanner" : {
      id: 'heroBanner',
      title: 'Hero Banner',
      icon: heroSVG,
      group: 'mostUsed',
      view: HeroBannerView,
      edit: HeroBannerEdit,
      restricted: false,
      mostUsed: false,
      sidebarTab: 1,
      security: {
        addPermission: [],
        view: [],
      }
    },
  
    
    // Full Width Divider
    "fullWidthDivider" : {
      id: 'fullWidthDivider',
      title: 'Divider',
      icon: dividerSVG,
      group: 'mostUsed',
      view: FullWidthDividerView,
      edit: FullWidthDividerEdit,
      restricted: false,
      mostUsed: false,
      sidebarTab: 1,
      security: {
        addPermission: [],
        view: [],
      }
    },

    // Stitch Divider
  "stitchDivider" : {
    id: 'stitchDivider',
    title: 'Stitches',
    icon: stichSVG,
    group: 'mostUsed',
    view: StitchDividerView,
    edit: StitchDividerEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  },


  // Background Heading And Lede
  "backgroundHeadingAndLede" : {
    id: 'backgroundHeadingAndLede',
    title: 'Head & Lede',
    icon: headAndLeadSVG,
    group: 'text',
    view: BackgroundHeadingAndLedeView,
    edit: BackgroundHeadingAndLedeEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  },

  
  // Metrics Block
  "metrics" : {
    id: 'metrics',
    title: 'Metrics',
    icon: chronoSVG,
    group: 'text',
    view: MetricsView,
    edit: MetricsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  },

  // NOT BEING USED ACTIVELLY
  // // Filterable Gallery
  // "filterableGallery" : {
  //   id: 'filterableGallery',
  //   title: 'Gallery',
  //   icon: appsSVG,
  //   group: 'media',
  //   view: FilterableGalleryView,
  //   edit: FilterableGalleryEdit,
  //   restricted: false,
  //   mostUsed: false,
  //   sidebarTab: 1,
  //   security: {
  //     addPermission: [],
  //     view: [],
  //   }
  // },

  
  // Related Content Cards
  "relatedContentCards" : {
    id: 'relatedContentCards',
    title: 'See Also',
    icon: seeAlsoSVG,
    group: 'mostUsed',
    view: RelatedContentCardsView,
    edit: RelatedContentCardsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  },
  
  // Related Content Cards
  "contactForm" : {
    id: 'contactForm',
    title: 'Contact Form',
    icon: contactSVG,
    group: 'text',
    view: ContactFormView,
    edit: ContactFormEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  },

  
  // NOT BEING USED
  // // Text with Icon
  // "iconText" : {
  //   id: 'iconText',
  //   title: 'Icon Text',
  //   icon: appsSVG,
  //   group: 'common',
  //   view: IconTextView,
  //   edit: IconTextEdit,
  //   restricted: false,
  //   mostUsed: false,
  //   sidebarTab: 1,
  //   security: {
  //     addPermission: [],
  //     view: [],
  //   }
  // },

  



  

  // SVG Stats
  "svgStats" : {
    id: 'svgStats',
    title: 'SVG Stats',
    icon: appsSVG,
    group: 'media',
    view: SVGStatsView,
    edit: SVGStatsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  },

  

  // External Link Card
  "externalLinkCard" : {
    id: 'externalLinkCard',
    title: 'Image Link',
    icon: contentLinkSVG,
    group: 'media',
    view: ExternalLinkCardView,
    edit: ExternalLinkCardEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  },

  // Testimonial Card
  "testimonialCard" : {
    id: 'testimonialCard',
    title: 'Testimonial',
    icon: userSVG,
    group: 'text',
    view: TestimonialCardView,
    edit: TestimonialCardEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  },

  // Picture Gallery
  "pictureGallery" : {
    id: 'pictureGallery',
    title: 'Gallery',
    icon: playSVG,
    group: 'media',
    view: PictureGalleryView,
    edit: PictureGalleryEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  },

  // Home Page
  "homePage" : {
    id: 'homePage',
    title: 'Home Page',
    icon: homeSVG,
    group: 'media',
    view: HomePageView,
    edit: HomePageEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    }
  },

  

};

export default function install(config) {

  Object.entries(customBlocks).forEach(([key, value]) => {
    config.blocks.blocksConfig[key]=value;
  });

  if (!config.widgets.widget.pick_object)
    config.widgets.widget.pick_object = ObjectBrowserWidgetMode('link');

  return config;
};

  // // Background Heading And Lede
  // config.blocks.blocksConfig["backgroundHeadingAndLede"] = customBlocks["backgroundHeadingAndLede"];

  // // Metrics Block
  // config.blocks.blocksConfig["metrics"] = customBlocks["metrics"];

  // // Filterable Gallery
  // config.blocks.blocksConfig["filterableGallery"] = customBlocks["filterableGallery"];
