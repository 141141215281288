const IHomePageSchema = {
    title: 'Home Page',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'video',
          'blackAndWhite'
        ],
      },
    ],

    properties: {
      video: {
        title: 'Stream URL',
        description: 'Insert Stream URL',
        widget: 'url',
      },

      blackAndWhite: {
        title: 'Black & White',
        description: 'Make video B&W',
        type: 'boolean',
      },


    },

    required: ['video'],
  };

  export default IHomePageSchema;
