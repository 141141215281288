const IRelatedContentCardsSchema = {
    title: 'Related Content Cards',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'selector',
          'hideMetadata',
          'blackAndWhite'
          // 'rows'
        ],
      },
    ],

    properties: {

      selector: {
        title: 'Selector Tag',
        description: 'The tag used to select content, e.g. "techniques" or "fabrics"',
        widget: 'text'
      },

      // rows: {
      //   title: 'Rows',
      //   description: 'Number of Rows',
      //   type: 'choice',
      //   choices: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4']]
      //   // default: ['1', '1']
      //   // vocabulary: 'volto.services.vocabularies.themes'
      // },

      hideMetadata: {
        title: 'Hide Metadata',
        description: 'Hides Metadata',
        type: 'boolean',
      },

      blackAndWhite: {
        title: 'Black & White',
        description: 'Make Picture B&W',
        type: 'boolean',
      },

    },

    required: ['selector'],
  };

  export default IRelatedContentCardsSchema
