/** Image Gallery actions.
* @module actions/content_types/get_pages
*/

import { GET_PAGES } from '../../constants/ActionTypes';

/**
* Get FAQ items.
* @function getPages
* @returns {Object} Images action.
*/
export function getPages(tag) {
  return (tag.length > 0) ? {
    type: GET_PAGES,
    request: {
      op: 'get',
      path: `/@search?portal_type=Document&Subject=${tag}&fullobjects=1`,
    },
 } : {
    type: GET_PAGES,
    request: {
      op: 'get',
      path: `/@search?portal_type=Document&fullobjects=1`,
    },
 };
}

// export function galleryImagesResult() {
//   return {
//     type : GET_GALLERY_IMAGES_SUCCESS,
//     result : {
//       id : '',
//       items: [],
//       items_total: 0
//     }
//   }
// }
// GET_GALLERY_IMAGES_SUCCESS returns the following
// {
//   result: {
//     '@id': 'http://localhost:3000/api/@search?portal_type=Image&Subject=joe',
//     items: [ [Object], [Object] ],
//     items_total: 2
//   },
//   type: 'GET_GALLERY_IMAGES_SUCCESS'
// }
