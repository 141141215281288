import React, { useEffect, useRef, useState, Component }  from 'react';
import {Logo, Navigation} from '@plone/volto/components'
// import { Swiper } from 'swiper/react';
import { connect } from 'react-redux';

import './static/css/gilroy.css';
import './static/css/app.css';
import './static/css/controls.css';
import './static/css/responsive.css';
import './static/css/swiper.min.css';
import './static/css/home-page.css';
import $ from 'jquery';
import muxjs from "mux.js";

// import ShakaPlayer from 'shaka-player-react';
// import 'shaka-player/dist/controls.css';
const [HP_LOADER_INIT, HP_LOADER_LOADCOMPLETED, HP_LOADER_COLLAPSING, HP_LOADER_COLLAPSED, HP_LOADER_FINISHED] = ["init", "load-completed", "collapsing", "collapsed", "finished"];
const SESSION_COOKIE_NAME = "GT_LOADER_SEEN";
const ONE_HOUR = 60;
const MIN_DESKTOP_WIDTH = 976;

class HomePageView extends Component {
  
  constructor(props) {
    super(props);
    this.manifestUri = this.props.video;
    this.moving = false;
    this.direction = 0;
    this.scrollY = 0;
    this.lastTransition = 0;
    this.delay = 500;
    this.doingTransition = false;
    this.newSession = true;

    let shaka_player = null;

    function setCookie(cname, cvalue, minutes) {
      const d = new Date();
      d.setTime(d.getTime() + (minutes * 60 * 1000));
      let expires = "expires="+d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    

    function getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return null;
    }

    function onHomePageRecently() {
      const sessionCookie = getCookie(SESSION_COOKIE_NAME);
      return sessionCookie != null;
    }

    if (typeof window !== 'undefined') {
      this.newSession = !onHomePageRecently();
      
      if (this.newSession) {
        $(".siteroot").addClass("homeMounting");
        $("#home-page-loader").addClass("init");
        this.showLoader();
      } else {
        $("#home-page-loader").css("display", "none !important");
      }
      
      // if (this.isDesktop()) {
      //   $("nav#navigation .hamburger-wrapper").removeClass("mobile");
      //   $("nav#navigation .hamburger-wrapper").removeClass("tablet");
      //   $("nav#navigation .hamburger-wrapper .hamburger").removeClass("mobile");
      //   $("nav#navigation .hamburger-wrapper .hamburger").removeClass("tablet");
      //   $("nav#navigation .hamburger-wrapper .hamburger").removeClass("only");
    
      //   $("nav#navigation .secondary.menu").removeClass("computer");
      //   $("nav#navigation .secondary.menu").removeClass("large");
      //   $("nav#navigation .secondary.menu").removeClass("widescreen");
      // }



      // $(".header-wrapper").addClass("homeHeroHeaderWrapper");
      // $("nav#navigation .secondary.menu").addClass("homeHeroNavigation");
      // $(".header-wrapper > .ui.container").addClass("homeHeroNavigationWrapper");





      

      // $(".header-wrapper > .container").css("width", "100vw !important");
      // $(".header-wrapper > .container").css("background-color", "red !important");
      
      window.muxjs = muxjs;
      shaka_player = document.createElement("script");
      shaka_player.src = "/js/shaka-player/shaka-player.ui.js";
      shaka_player.async = true;
      // shaka_player.onload = () => this.scriptLoaded();
      shaka_player.onload = () => {
        shaka.polyfill.installAll();
        this.initApp();
      }; 

      this.shakaPlayer = shaka_player;

      document.head.appendChild(shaka_player);
      this.applyStyling();
      setCookie(SESSION_COOKIE_NAME, "bypass.intro.home", ONE_HOUR);
    }    

    this.state = {
      visibility : "show",
      playact: "",
      playactReverse: "",
    };
  }

  showLoader() {
    if (this.newSession) {
      $("#progress-bar").addClass("loader-class-"+HP_LOADER_INIT);

      $( "#progress-bar-container" ).on( "activate", function( event ) {
        $( "#progress-bar-container" ).addClass("activated");
        $( "#progress-bar-container .menu-hint" ).addClass("activated");
      });

      $( "#progress-bar-container" ).on( "rotate-to-position", function( event ) {
        $( "#progress-bar-container" ).addClass("rotated");
      });

      $("#progress-bar-container").on("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", function() {
        if ($("#progress-bar-container").hasClass("rotated")) {
          // Prepare Home Page Usage
          if (!$("#home-page-loader").hasClass("hidden")) {
            setTimeout(() => {$("#home-page-loader").addClass("hidden");}, 1000);
            setTimeout(() => {
              $("#home-page-loader").addClass("removed");
              $("body").removeClass("homeMounting");
            }, 1500);
            
            // console.log("its placed");
          }
        }
        
      });


      $("#progress-bar").on("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", function() {
        const loaderPhases=[HP_LOADER_INIT, HP_LOADER_LOADCOMPLETED, HP_LOADER_COLLAPSING, HP_LOADER_COLLAPSED, HP_LOADER_FINISHED] ;
        let nextPhaseIndex = loaderPhases.map( e => $("#progress-bar").hasClass("loader-class-"+e)).findIndex((x) => x==false);
        
        if ((nextPhaseIndex != -1) ) {
          const newClass = "loader-class-" + loaderPhases[nextPhaseIndex];
          if (loaderPhases[nextPhaseIndex] == HP_LOADER_COLLAPSING ) {
            $( "#progress-bar-container" ).trigger("activate");
            $(".brandText").addClass("hidden");
          } 

          if (loaderPhases[nextPhaseIndex] == HP_LOADER_COLLAPSED ) {
            $( "#progress-bar-container" ).trigger("rotate-to-position");
          }

          $("#progress-bar").addClass(newClass);
          // console.log("added class " + newClass);

        } 

        // $("#home-page-loader").addClass("hidden");
      });
    
    }
  }

  initApp() {
    try {
      // console.log("initApp()");
      // Check to see if the browser supports the basic APIs Shaka needs.
      if ( (typeof window !== 'undefined') && (shaka.Player.isBrowserSupported())) {
        // Everything looks good!
        this.initPlayer();
    
      } else {
        // This browser does not have the minimum set of APIs we need.
        console.error('Browser not supported!');
      }

      // console.log("initApp() finished");
    } catch (e) {
      console.error("Got exception in initApp -> " + e);
    }

  }

  async initPlayer() {
    
    try {
      // console.log("initPlayer()");
      // Create a Player instance.
      
      const videoContainer = document.getElementById('videocontainer');
      if (videoContainer!=undefined) {
        $(".ui.pointing.secondary a.item").addClass("darkmode");
        
        // console.log("Adding video tag");
        $(videoContainer).append("<video id='fullscreen-video' muted='' loop='loop' width='100%' playsinline disablePictureInPicture autoPlay muted></video>");
        const video = document.getElementById('fullscreen-video');
        // console.log("Added child node that can be found using standard DOM queries: ");
        // console.log(video);

        const player = new shaka.Player(video);
        const ui = new shaka.ui.Overlay(player, videoContainer, video);

        

        

        const eventManager = new shaka.util.EventManager();
        eventManager.listen(player, `buffering`, (event) => {
          if (event.buffering == false) {
            // console.log('We are playing!');
            eventManager.unlisten(player, 'buffering');
            const newClass = "loader-class-" + HP_LOADER_LOADCOMPLETED;
            $("#progress-bar").addClass(newClass);
          } else {
            // console.log("Buffering");
            // console.log(event);
          }
        });

        eventManager.listen(player, `loading`, (event) => {
            // console.log("loading");
            // console.log(event);
        });


        // See https://github.com/google/shaka-player/blob/master/ui/ui.js
        const config = {
          addSeekBar: false,
          addBigPlayButton: false,
          controlPanelElements: [],
          overflowMenuButtons: [],
          contextMenuElements: [],
          doubleClickForFullscreen: false,
          enableKeyboardPlaybackControls: false,
          enableFullscreenOnRotation: false,
          forceLandscapeOnFullscreen: false,
          enableTooltips: false,
        }
        

        ui.configure(config);
        const controls = ui.getControls();

        // Attach player to the window to make it easy to access in the JS console.
        window.player = player;

        // Listen for error events.
        player.addEventListener('error', this.onErrorEvent);

        // Try to load a manifest.
        // This is an asynchronous process.
        // console.log("loading video " + this.manifestUri);
        await player.load(this.manifestUri);
        // This runs if the asynchronous load is successful.
        // console.log('The video has now been loaded!');
      }
      
    } catch (e) {
      console.error("Shaka Player Error: " + e);
      // onError is executed if the asynchronous load fails.
      this.onError(e);
    }
  }

  onErrorEvent(event) {
    // Extract the shaka.util.Error object from the event.
    this.onError(event.detail);
  }

  onError(error) {
    // Log the error.
    try {
      console.error('Error code', error.code, 'object', error);
    } catch (e)  {

    }
  }


  onPlayerErrorEvent(errorEvent) {
    // Extract the shaka.util.Error object from the event.
    this.onPlayerError(errorEvent);
  }

  onPlayerError(error) {
    // Handle player error
    console.error('Error code', error.code, 'object', error);
  }

  onUIErrorEvent(errorEvent) {
    // Extract the shaka.util.Error object from the event.
    this.onPlayerError(errorEvent);
  }

  initFailed(errorEvent) {
    // Handle the failure to load; errorEvent.detail.reasonCode has a
    // shaka.ui.FailReasonCode describing why.
    console.error('Unable to load the UI library!');
  }

  getColorSetting() {
    try {
      return this.props.data.blackAndWhite ? "blackAndWhite" : "color";
    } catch(e) {
      return "color";
    }
  }

  applyStyling = () => {
    try{
      $(".ui.basic.segment.header-wrapper").css("background", "transparent !important");
      // $(".logo-nav-wrapper .navigation").css("opacity", "0");
      // $(".logo-nav-wrapper .logo").css("filter", "invert(1)");  
      $( ".documentFirstHeading" ).hide();
    } catch (e) {
      // protecting from classes having been removed
    }
  }

  isEdit() {
    return ((!this.isSSR()) && ($(".section-edit").length != 0));
  }

  shouldAnimate() {
    // console.log("************ this.shouldAnimate() = " + ($(".section-edit") != null));
    // console.log($(".section-edit"));
    return ((!this.isSSR()) && (!this.isEdit()));
  }

  isDesktop() {
    return (window!=undefined && window.innerWidth > MIN_DESKTOP_WIDTH);
  }

  componentDidMount() {
    try {
      
      //let hero = document.querySelector('#home-page-hero');
      // document.addEventListener('wheel', (e) => {
      //   this.mousewheel(e);
      // });
      $(".content-area").css("margin", "0");
      $(".content-area").css("padding", "0");
      $(".breadcrumbs").hide();
      $(".documentFirstHeading").hide();
      $(".logo-nav-wrapper .logo img").addClass("nofilter");

      // let scrolpos = window.scrollY;
      // let heroheight = this.calcHeight();
      // if (scrolpos < heroheight) {
      //   this.hideBelowFold();
      // } else {
      //   this.showBelowFold();
      // }
    } catch (e) {
      console.log(e);
    }
  }

  componentWillUnmount() {    
    $(".siteroot").removeClass("homeMounting");
    $("#home-page-loader").removeClass("init");
  
    
    // if (this.isDesktop()) {
    //   $("nav#navigation .hamburger-wrapper").addClass("mobile");
    //   $("nav#navigation .hamburger-wrapper").addClass("tablet");
    //   $("nav#navigation .hamburger-wrapper").addClass("only");
    //   $("nav#navigation .hamburger-wrapper .hamburger").addClass("mobile");
    //   $("nav#navigation .hamburger-wrapper .hamburger").addClass("tablet");
    //   $("nav#navigation .hamburger-wrapper .hamburger").addClass("only");
    //   $("nav#navigation .secondary.menu").addClass("computer");
    //   $("nav#navigation .secondary.menu").addClass("large");
    //   $("nav#navigation .secondary.menu").addClass("widescreen");
    // }

    // $(".header-wrapper").removeClass("homeHeroHeaderWrapper");
    // $("nav#navigation .secondary.menu").removeClass("homeHeroNavigation");
    // $(".header-wrapper > .ui.container").removeClass("homeHeroNavigationWrapper");
    // $(".logo-nav-wrapper .logo img").removeClass("nofilter");

    $(".breadcrumbs").show();

  }




  componentDidUpdate(prevProps) {

  }


  isSSR() {
    return (typeof window === 'undefined');
  }

  hideOnEdit() {
    if (this.isEdit()) {
      return {
        display: "none"
      }
    } else {
      return {}
    }
  }

  hideOnPublic() {
    if (!this.isEdit()) {
      return {
        display: "none"
      }
    } else {
      return {}
    }
  }
  
  render() {
    return (
        
      <div id="homePageWrapper"> 
        <div id="home-page-loader">
          <div id="company-name" className="brandText">Welcome to GT Portugal</div>
          <div id="progress-bar-container">
            <div id="progress-bar"></div>
            {/* <div className="menu-hint menu-hint-top"></div>
            <div className="menu-hint menu-hint-bottom"></div> */}
          </div> 
          <div id="business-areas" className="brandText"><span>Textiles</span><span>Footwear</span><span>Homeware</span></div>
        </div>
        <div className={`homePageBlock section `} 
            id="home-page-hero">

          <div id="white-bar"></div>

          <div className="swiper-placeholder" style={this.hideOnPublic()}>
          Your Video Hero goes here.
          </div>        

          <div className="swiper-container" style={this.hideOnEdit()}>
            <div id="white-board" className={this.state.playact}></div>
            <div id="white-board-reverse" className={this.state.playactReverse}></div>
            <div className="swiper-wrapper">
              <div className="swiper-slide" data-history="case-0" data-hash="case-0" data-title="">
                <div id="home-intro" className="stretchhh">

                  <svg viewBox="0 0 300 400" id="home-svg" preserveAspectRatio="xMidYMid slice">
                    <defs>
                      <mask id="logo-mask" x="0" y="0" width="100%" height="100%">
                        <rect x="0" y="0" width="100%" height="100%" fill="#fff"></rect>
                        <g transform="translate(-35.000000,350.000000) scale(0.181000,-0.181000)"
                        fill="#000000" stroke="none">
                        <path d="M 600.5 1179.9 c -71.9 -6.8 -135.3 -31.8 -187 -73.8 c -40.3 -32.7 -72.8 -76.1 -92.4 -123.4 c -23.2 -56.1 -30.7 -122.3 -21.1 -186 c 8.8 -58 30.2 -108.2 65.4 -153.7 c 10.4 -13.4 35.1 -38.3 48.6 -49 c 46.8 -37.1 100.2 -59.2 163.6 -67.7 c 16.4 -2.2 63.7 -2.5 79.4 -0.5 c 66.3 8.4 124.8 38.2 161.7 82.5 l 7.3 8.8 l 0 -41.1 l 0 -41 l 59 0 l 59 0 l 0 168 l 0 168 l -144 0 l -144 0 l 0 -62 l 0 -62 l 62 0 c 34.1 0 62 -0.3 62 -0.6 c 0 -0.4 -1.9 -3.4 -4.1 -6.8 c -33.3 -49 -95.9 -74.9 -161.9 -67.1 c -49.3 5.8 -90.9 29.1 -119 66.5 c -8.3 11.2 -19.5 33.1 -23.9 47 c -16 50.9 -12.4 109.5 9.3 154.5 c 8.7 18 18.2 31.2 32.5 45.6 c 18.1 18.1 37 30 60.8 38.2 c 35.6 12.4 78.4 12.8 114.2 1 c 11 -3.6 29.2 -12.5 39 -19.1 c 23.5 -15.7 43.1 -41 52.6 -67.6 l 3.3 -9.1 l 87.3 -0.3 c 48.1 -0.1 87.9 0 88.6 0.2 c 1.4 0.6 -3.4 19.5 -9 36.1 c -31.5 92.5 -106 164.4 -204.2 197 c -24 8 -43.5 12.4 -71 16.1 c -13.4 1.8 -59.9 2.6 -74 1.3 z"></path>
                        <path d="M 994 1099.5 l 0 -70.5 l 93 0 l 93 0 l 0 -247 l 0 -247 l 77.5 0 l 77.5 0 l 0 247 l 0 247 l 92.5 0 l 92.5 0 l 0 70.5 l 0 70.5 l -263 0 l -263 0 l 0 -70.5 z"></path>
                        </g>
                      </mask>
                    </defs>
                    <rect x="0" y="0" width="100%" height="100%"></rect>
                  </svg>

                  {/* <h1>
                    <span>Welcome to </span>
                    <span>GT Portugal</span>
                    <span>+ Production</span>
                  </h1> */}
                  
                  <h1>
                    <span>Responsible</span>
                    <span>Development</span>
                    <span>+ Production</span>
                  </h1>
                  {/* <h3>
                    Your outstanding partner<br/> in the fashion industry.
                  </h3>
                  <div className="action-hint"> Scroll for more</div> */}

                </div>
                <div id="home-contact-link">
                  <a href="./about-us/contact">
                    <span className="link-label">Get in touch</span>
                    <span className="link-hover">Get in touch</span>
                  </a>
                </div>

                <div id="videocontainer" className={this.getColorSetting()}>
                  {/* <video id="fullscreen-video"
                      muted=""
                      loop="loop"
                      width="100%"
                      autoPlay muted></video> */}

                </div>
              </div>
            </div>
            <div className="swiper-pagination"></div>
          </div>
          <div class="textBackgroundOverlay"></div>

        </div> 
        {/* <div id="homePageLogoWrapper"><Logo/></div> */}
      </div>  
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  // console.log(state);

  const propsStruct = {
    video: ownProps.data.video,
    scrollY: (typeof window !== 'undefined') ? window.scrollY : 0,
    count: 0,
    delay: 500,
    lastTransition: 0,
    targetPosition: 0,
    moving: false 
  }

  return propsStruct;
}

const mapDispatchToProps = {
  // ... normally is an object full of action creators
  // getPagesFromPlone: getPages
}

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(HomePageView);
